@import 'src/static/css/global/global.scss';
.gl::-webkit-scrollbar {
    width: 11px;
    -webkit-appearance: none;
}
.gl::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid $white;
    opacity: .5;
    background: #AAA;
}
.category-main-container {
    display: inline-block;
    width: 100%;
    padding-top: 25px;
    .ttl-cntnr {
        text-align: center;
        border-bottom: 1px solid $grey-14;
        span.tot-products,
        span.tot-products span {
            font-size: 18px;
            font-weight: 400;
            color: #7c7f80;
            padding-left: 5px;
        }
        &.search-head {
            text-align: left;
            span.tot-products span {
                font-size: 14px;
                color: grey;
            }
        }
    }
    .select-wrap {
        position: relative;
        &::after {
            position: absolute;
            top: 0;
            right: 0;
            width: 10px;
            height: 100%;
            content: '';
            background: url(/static/icons/arrow-down-light.svg);
        }
    }
    .filter-select-wrap {
        display: inline-block;
        width: auto;
        position: relative;
        &::after {
            position: absolute;
            top: 0;
            right: 0;
            width: 15px;
            height: 100%;
            content: '';
            background: url(https://static1.hkrtcdn.com/gritzo/static/media/icons/arrow-down.svg) right 4px center no-repeat;
            background-size: 10px auto;
            pointer-events: none;
            z-index: 1;
        }
    }
    .pag-cntnr .filter-select {
        height: 30px;
        display: inline-block;
        padding: 0 10px;
        margin: 0 0 0 10px;
        width: auto;
        border: 1px solid $grey-14;
        background: $white;
        border-radius: 5px;
        font-weight: 500;
        cursor: pointer;
        position: relative;
        &.paging-list {
            width: 60px;
        }
    }
    .show-product-dropdown {
        margin-left: 20px;
    }
    .pag-cntnr {
        display: inline-block;
        width: 100%;
        border-top: 0;
        border-bottom: 1px solid $grey-14;
        padding-bottom: 10px;
        padding-top: 10px;
        margin-top: 10px;
        font-size: .9em;
        .cont-lft {
            float: left;
        }
        .cont-rht {
            float: right;
            #topPager {
                color: #7c7f80;
                margin-top: 16px;
                float: left;
                span {
                    color: #7c7f80;
                    float: left;
                    vertical-align: middle;
                    line-height: 14px;
                    font-size: .9em;
                }
                span.sort-items {
                    border-right: 1px solid $grey-14;
                    padding: 0 5px 0 5px;
                    display: inline-block;
                    line-height: 14px;
                    font-size: 12px;
                }
                span.sort-items:nth-of-type(4) {
                    border-right: 0;
                }
            }
        }
    }
    div#variantResultView>div {
        display: inline-block;
        width: 100%;
    }
    .ttl-cntnr h1.fnt-ttl {
        max-width: 100%;
        padding-bottom: 15px;
        display: inline-block;
        font-weight: 400;
        font-size: 32px;
        color: #141516;
        margin: 0;
        line-height: 40px;
        text-align: center;
    }
}
.span4 {
    width: 19%;
    margin-left: 0;
    padding: 0 10px;
    box-sizing: border-box;
    box-shadow: none;
    float: left;
}
.paginationBar-wrap {
    padding: 0 2%;
    .paginationBar {
        float: left;
        width: 100%;
        padding: 5px;
        background: #f4f4f4;
        line-height: 1.3em;
        margin-bottom: 5px;
        text-transform: uppercase;
        margin-top: 20px;
    }
}

.banner-content {
    .topbanner {
        display: inline-block;
        width: 100%;
        .slider-left {
            margin-bottom: 40px;
            width: 63%;
            float: left;
            .slider-one {
                background: #ddd;
                width: 100%;
                .main-carousel {
                    padding: 0;
                    overflow: hidden;
                    .owl-carousel {
                        height: auto;
                        img {
                            max-width: 100%;
                            width: 100%;
                            height: auto;
                            vertical-align: top;
                        }
                    }
                }
                .owl-dots {
                    position: absolute;
                    width: 100%;
                    left: 0;
                    bottom: 15px;
                    .owl-dot span {
                        background-color: #f4f4f4;
                        color: #000;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        margin-left: 0;
                        border-width: 1px 0 0 1px;
                        text-indent: -9999px;
                        overflow: hidden;
                        float: none;
                        margin: 0;
                        margin-right: 9px;
                        display: inline-block;
                    }
                    .owl-dot.active span {
                        background: $yellow2 !important;
                        &:hover {
                            background: $yellow2 !important;
                        }
                    }
                }
            }
        }
        .slider-right {
            width: 34%;
            float: right;
            &>div {
                img {
                    max-width: 100%;
                    width: 100%;
                    height: auto;
                    vertical-align: top;
                }
                margin-bottom: 15px;
                background: #ddd;
                overflow: hidden;
                &.slider-three {
                    margin-bottom: 0;
                }
            }
        }
    }
}
.category-product-slider {
    .trending-now-widget {
        width: 95%;
        padding: 0 0 0 15px;
        margin: 0 auto;
        display: block;
    }
    .owl-carousel.owl-drag .owl-item {
        width: 285.229px !important;
        margin-right: 0 !important;
    }
    .content-widget-heading {
        margin: 0;
        h2 {
            font-size: 22px;
            color: #141516;
            font-weight: 400;
            margin: 20px 0 40px;
        }
    }
}
.category-data-container {
    margin-top: 40px;
    .seoContainer {
        border-top: 1px solid $grey-14;
        padding-top: 10px;
        h2 {
            font-weight: 600;
            color: #141516;
            font-size: 1.3em;
        }
        ul {
            list-style: disc;
            margin-left: 16px;
            li {
                margin-bottom: 0;
            }
        }
    }
}
.category-data-container * {
    margin-bottom: 10px;
    margin-top: 0;
}
.hco-footer-content {
    width: 100%;
    float: left;
    padding: 0 20px;
    p {
        margin: 0;
        padding: 0;
    }
    p {
        font-size: 14px;
        line-height: 26px;
        color: #25292b;
        font-weight: 400;
      }
    table {
        border: none;
        margin: 0 auto;
        width: auto !important;
        a:hover {
            text-decoration: none;
            color: #177a99;
        }
    }
    table tr td {
        text-align: left;
        padding: 0 15px;
    }
    tr,
    td {
        border: none
    }
}
.filter-itms-cntnr {
    margin: 5px 0;
    .filter-label {
        margin-top: 4px;
    }
    span#searchRelevanceFeedback {
        margin-right: 9px;
    }
    span.fnt-bold.search-feedback-response {
        cursor: pointer;
        text-decoration: underline;
        font-weight: 600;
        line-height: 21px;
        color: #25292b;
        font-size: 12.6px;
    }
    span {
        font-size: .9em;
        line-height: 11.2px;
    }
    .filter-label {
        text-transform: uppercase;
        margin-bottom: 0;
        font-weight: 700;
        font-size: 10px;
        white-space: nowrap;
        display: inline-block;
        margin-right: 5px;
        margin-top: 12px !important;
    }
    .filter-box {
        padding: 0px;
        font-size: 13px;
        line-height: 30px;
        white-space: nowrap;
        display: inline-block;
        text-transform: capitalize;
        border: 0;
        background: transparent;
        width: auto;
        margin: 3px 3px 0 0;
        font-weight: 400;
    }
    span.icn.icn-close1.remove-varnt {
        background: url(https://static1.hkrtcdn.com/gritzo/static/media/category/close-s.png) no-repeat center;
        width: 12px;
        height: 10px;
        display: inline-block;
        vertical-align: top;
        background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/category/close.png);
        background-color: #eee;
        margin-left: 2px;
        cursor: pointer;
        margin-top: 10px;
    }
}
.variantFlavour {
    display: flex;
    margin-top: 10px;
    .vegnonveg {
        margin-top: 4px
    }
}
.variant-tile {
    width: 100%;
    padding: 30px 30px 30px 0;
    margin-bottom: 30px;
    position: relative;
    box-sizing: border-box;
    float: left;
    border: 1px $grey-48 solid;
    border-radius: 3px;
    background: $white;
    .hk-variants-box-container {
        border-right: 1px $grey-48 solid;
        padding-right: 20px;
        margin-right: 20px;
    }
    &:hover {
        box-shadow: 6px 6px 10px 0 rgba(0, 0, 0, .11);
        z-index: 1;
    }
    &.weaved-tile .banner {
        border: 1px solid #ccc;
        min-height: 300px;
        font-size: 16px;
    }
    .banner {
        border: 1px solid #ccc;
        min-height: 300px;
        font-size: 16px;
        .head {
            background-position: 0 -186px;
            .text {
                font-size: 16px;
                color: $white;
                padding: 12px;
            }
        }
        .content .text {
            color: #726c0f;
            padding: 20px;
            text-align: center;
            word-wrap: break-word;
            line-height: 30px;
            height: 190px;
            font-size: 24px;
            -webkit-transform: translateY(-0%);
            -ms-transform: translateY(-0%);
            transform: 0;
            margin-top: 25px;
            .icon-weaved-tile {
                display: none;
            }
        }
        span.icon-weaved-tile {
            background: url(https://static1.hkrtcdn.com/gritzo/static/media/category/weaved_tile_icons.png) no-repeat;
            background-position: -36px 0;
            height: 25px;
            width: 25px;
            display: inline-block;
            vertical-align: bottom;
            background-position: -142px 0;
        }
    }
    .banner[data-type="9"] {
        .content {
            color: #1a3848;
            height: 240px;
        }
        .text {
            color: #1a3848;
            text-align: center;
            word-wrap: break-word;
            line-height: 30px;
            height: 190px;
            font-size: 24px;
            padding: 20px;
            position: relative;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            span.icon-auth {
                background: url(https://static1.hkrtcdn.com/gritzo/static/media/category/weaved_tile_sprite.png) no-repeat;
                background-position: -50px 0;
                height: 85px;
                width: 85px;
                display: inline-block;
                margin-bottom: 10px;
            }
        }
    }
    .banner[data-type="2"] {
        .head {
            background-position: 0 -186px;
        }
        .content .text {
            color: #726c0f;
        }
    }
    a.oos-cntnr {
        position: absolute;
        padding: 5px;
        border: 1px solid #333;
        border-radius: 2px;
        background-color: #fafafa;
        color: #333;
        display: block;
        width: 92px;
        margin-left: 60px;
        font-size: 12px;
        text-align: center;
        line-height: 21px;
        top: 70px;
        z-index: 9;
    }
    &.weaved-tile {
        &:hover {
            box-shadow: none;
        }
        .head {
            background: url(https://static1.hkrtcdn.com/gritzo/static/media/category/weaved_tile_sprite.png) no-repeat;
            background-position: 0 -112px;
            background-size: cover;
            height: 50px;
            text-align: center;
            font-weight: 600;
        }
        .weaved-tile .banner[data-type="2"] .head {
            background-position: 0 -186px;
        }
        .weaved-tile .banner[data-type="8"] .head {
            background-position: 0 -637px;
        }
    }
    .hk-variants {
        padding: 0;
        margin: 0;
        .wishlist {
            opacity: 1 !important;
            right: 0;
        }
    }
    .variant-highlighter {
        display: inline-block;
        width: 100%;
        float: left;
        font-weight: 100;
        font-size: 11px;
        margin-right: 2px;
        display: inline-block;
        color: #787878;
    }
    span.vrnt-loyalty-cash-text {
        font-size: 13px;
        color: #25292b;
        font-weight: 400;
        display: inline-block;
        margin-right: 4px;
        line-height: 21px;
    }
    span.price-per-kg-value {
        font-size: 13px;
        margin-right: 2px;
        display: inline-block;
        color: #424242;
        font-weight: 600;
        line-height: 21px;
    }
    .tile-tag-list {
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 1;
    }
    .tile-tag.rank-tag {
        margin-bottom: 5px;
        padding: 0;
        display: inline-block;
        color: $white;
        min-height: 20px;
        width: 35px;
        height: 35px;
        border-radius: 2px;
        background: url(https://static1.hkrtcdn.com/gritzo/static/media/category/offersCircle.svg) no-repeat;
        background-size: cover;
        text-align: center;
        box-sizing: border-box;
        padding-top: 7px;
        border: 0 !important;
        position: relative;
        z-index: 11;
        font-weight: 700;
    }
    span.tag-icon-hash,
    span.tag-icon-hash * {
        color: $white;
    }
}
.load-more-btn {
    text-transform: capitalize;
    line-height: inherit;
    cursor: pointer;
    color: #25292b;
    box-shadow: none;
    border-radius: 3px;
    display: block;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: .3em .8em;
    width: 224.8px !important;
    display: block !important;
    text-shadow: none;
    min-width: 200px;
    background: none;
    margin: 0 auto;
    font-size: 14px;
    font-weight: 400;
    &:hover {
        border: 1px solid $blue-3;
        color: $blue-3;
        background: none;
    }
}
a.resultCnt.perPgActive {
    color: #25292b;
    line-height: 14px;
    font-weight: 700;
}
.flash-sale-ending-offers {
    margin-top: 40px;
    min-height: 210px;
    background: url(https://static1.hkrtcdn.com/gritzo/static/media/category/gradient_14.svg);
    padding: 20px 15px;
}
.flash-wrapper {
    width: 100%;
    background: $white;
    box-sizing: border-box;
    padding: 0 20px;
    height: 280px;
    .ending-timer {
        height: 40px;
        box-sizing: border-box;
        padding-top: 7px;
        height: 40px;
    }
    .hk-variants .wishlist.wishlist--pdp-general-icon.wishlist--pdp-general-icon-flashsale {
        display: none !important;
    }
    a.variant-link {
        text-align: left;
    }
    .variant-info {
        display: inline-block;
        width: 59%;
    }
    .main-timer {
        display: inline-block;
        color: #dc2b00;
        width: 50%;
        font-weight: 600;
        float: left;
        text-align: left;
    }
    .rtt-view-dls {
        color: #25292b;
        float: right;
        width: 50%;
        font-size: 14px;
        text-align: right;
        font-weight: 400;
    }
    .hk-variants .flashSale--cont .flashSale--units-sold-bar .flashSale--units-bar {
        line-height: 21px;
    }
    div#deal-timeout1529988817324_0 {
        display: inline-block;
        color: #dc2b00;
        font-weight: 600;
        font-size: 14px;
    }
    .hk-variants {
        border: 1px solid #eaeaea;
        display: inline-block;
        box-sizing: border-box;
        min-height: 215px;
        width: 49%;
        box-shadow: 2px 2px 10px -1px rgba(0, 0, 0, .11);
        z-index: 9;
        padding: 0;
    }
    .hk-variants .flashSale--cont {
        height: 43px;
        border-bottom: 1px solid #eaeaea;
        padding: 5px;
        margin-bottom: 10px;
    }
    .hk-variants .flashSale--cont .flashSale--icon {
        width: 59px;
        border-right: 1px dotted #eaeaea;
    }
    .hk-variants .flashSale--cont .flashSale--units-sold-bar {
        width: calc(100% - 59px);
    }
    .flashSale--deal-timeoutprogress {
        margin-top: 2px !important;
    }
    .flashSale--timer {
        display: none !important;
    }
    .flashSale--units-bar {
        text-align: left;
    }
}
.inline {
    color: #000;
    font-weight: 600;
    font-size: 16px;
}
.icon-plus-multiple-text {
    padding: 20px 16px;
    overflow: hidden;
    background: $white;
    border-bottom: 4px solid #e7e8e9;
    display: inline-block;
    width: 100%;
    .card-title-default {
        border-bottom: 0;
        padding: 0;
        margin-bottom: 20px;
        span {
            color: #000;
            font-weight: 600;
            font-size: 16px;
        }
    }
    .full-width.multiple-text-box {
        display: inline-block;
        width: 100%;
        .icon-plus-image {
            width: 40%;
            height: 138px;
            float: left;
            img {
                height: 100%;
                max-width: 132px;
            }
        }
        .icon-plus-text-box {
            width: 60%;
            height: 100%;
            float: left;
            .icon-plus-right {
                margin-left: 5px;
                width: 100%;
                float: left;
                p {
                    border-bottom: 1px solid #ccc;
                    width: 100%;
                    float: left;
                    padding: 8px;
                    margin: 0;
                    span.arrow-icn {
                        float: right;
                        width: 5%;
                        margin-top: 2.5px;
                    }
                }
            }
            .icon-plus-right:first-child {
                border-top: 1px solid #ccc;
            }
        }
    }
    .banner-box {
        width: 33%;
        float: left;
        padding-bottom: 5px;
        height: auto;
        .image-box img {
            margin: 0 auto;
            max-width: 100%;
        }
    }
}
.blog-sec {
    border-bottom: 0;
    .banner-box {
        width: 100%;
    }
}
.leafNodeWrapper {
    position: relative;
    min-height: 59px;
    width: 100%;
    .sort-menu {
        position: absolute;
        top: 0;
        right: 0;
        height: 44px;
        width: 45px;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
        @include set-box-shadow(0, 3px, 6px, $grey-31);
    }
    .banner-cntr {
        >img {
            max-width: 100%;
            width: 100%;
        }
    }
}
.leafNodeContainer {
    overflow: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    background: $grey-0;
    float: left;
    position: relative;
    width: calc(100% - 60px);
    padding: 10px 0;
    min-height: 45px;
    ul {
        margin-left: 10px;
        display: inline-block;
        white-space: nowrap;
        li {
            display: inline-block;
            padding: 0;
            color: $black17;
            font-weight: 400;
            margin-bottom: 0;
            h1 {
                margin-bottom: 0;
                font-weight: 600;
                font-size: 14px;
                margin-top: 0;
                line-height: 1.4em;
            }
            a {
                padding: 2px 13px;
                color: $black;
                font-size: 14px;
                line-height: 23px;
                display: block;
                font-weight: $medium;
            }
            &.selected {
                border: 1px $yellow2 solid;
                border-radius: 4px;
                background: $white;
            }
        }
    }
}
.go-to-top-cntnr {
    top: auto;
    bottom: 50px;
    z-index: 9999;
}
.catalog-cloud {
    position: fixed;
    height: 100%;
    overflow: auto;
    top: 0;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    font-size: 14px;
    left: 0;
    right: 0;
    z-index: 111111;
    .sorting-container-inner {
        position: absolute;
        background: $white;
        bottom: 0;
        width: 100%;
        left: 0;
        padding: 24px;
    }
    .sort-head {
        // padding: 0px 0px 10px;
        // width: 100%;
        margin-bottom: 15px;
    }
    .sort-label {
        display: inline-block;
        line-height: 24px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.38;
    }
    .right {
        float: right;
        margin-top: 3px;
        img {
            width: 14px;
            height: 14px;
        }
    }
    .btn {
        display: inline-block;
        padding: 5px 10px;
        border-radius: 3px;
        margin: 0 5px;
        text-transform: capitalize;
        transition: background 1s;
        text-align: center;
        font: 400 13.3333px Arial;
        position: relative;
    }
    .sorting-items li {
        padding: 13px 0 18px 0;
        border-bottom: 1px solid #e0e0e0;
        margin: 0;
        /* border-left: 2px solid #ccc; */
        color: $black17;
        label {
            font-size: 15px;
            font-weight: 600;
            line-height: 1.33;
        }
    }
    
    .fltr-action {
        padding: 10px;
        width: 100%;
        .btn {
            margin: 0 6px;
        }
    }
    .action-label {
        display: inline-block;
        line-height: 24px;
    }
    .btn-list {
        display: inline-block;
    }
    .btn-list button {
        font-size: 12px;
        padding: 5px;
    }
    .btn.blue {
        background-color: $blue-3;
        border: 1px solid $blue-3;
        color: $white;
    }
}
.fltr-accordion {
    .arrow-up {
        background: url(https://static1.hkrtcdn.com/gritzo/static/media/category/arrow_up.png) no-repeat scroll 50% center rgba(0, 0, 0, 0);
        float: right;
        width: 40px;
        height: 20px;
        display: none;
    }
    .arrow-down {
        background: url(https://static1.hkrtcdn.com/gritzo/static/media/category/arrow_down.png) no-repeat scroll 50% center rgba(0, 0, 0, 0);
        float: right;
        width: 40px;
        height: 20px;
        display: inline-block;
    }
    .fltr-type .applied {
        color: #2f8e2f;
        font-size: 14px;
        display: none;
        &:last-child {
            display: inline;
        }
    }
    .fltr-type {
        border-bottom: 1px solid $grey-14;
    }
    .fltr-type:first-child {
        border-top: 1px solid $grey-14;
    }
    .fltr-type .fltr-name {
        padding: 10px;
        background-color: #fafafa;
    }
    ul.fltr-items {
        background-color: $white;
        max-height: 210px;
        overflow: hidden;
        overflow-y: auto;
        display: none;
        height: auto;
        transition: all .6s ease-out;
        li:first-child {
            border-top: 1px solid $grey-14;
        }
        li {
            padding: 10px;
            border-bottom: 1px solid $grey-14;
            margin: 0;
            color: $black17;
            input {
                vertical-align: middle;
                width: 20px;
                height: 20px;
                margin-right: 4px;
            }
            span {
                color: #646464;
            }
            span.cont-rht {
                float: right;
            }
        }
    }
    .priceFilter {
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .priceText {
        text-align: left;
        margin-left: 10px;
        margin-bottom: 10px;
    }
    .priceFilter input {
        max-width: 40%;
        display: inline-block;
        background-color: #f4f4f4;
        -webkit-appearance: none;
        -ms-appearance: none;
        border: 1px solid #969696;
        padding: 8px 10px;
        width: 100%;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border-radius: 0 !important;
    }
    span.priceFilterSep {
        display: inline-block;
        margin: 5px;
        font-size: 20px;
        font-weight: 600;
    }
    .open-drop-down {
        .arrow-up {
            display: inline-block
        }
        .arrow-down {
            display: none;
        }
        .fltr-items {
            display: block;
        }
    }
}
h2.errorH1 {
    display: inline-block;
    width: 100%;
    padding: 5px 16px;
    margin: 0 0 20px;
    font-size: 1.5em;
}
.disabled {
    color: #ccc !important;
    * {
        color: #ccc !important;
    }
}
.filterResultsCount {
    position: fixed;
    z-index: 999999;
    left: 35%;
    top: 104px;
    background: rgba(0, 0, 0, .7);
    border-radius: 5px;
    width: 30%;
    text-align: center;
    padding: 2px;
    .variantCount {
        color: $white;
    }
}
// Ab Cards
svg.abcardfilter--cont-close {
    position: absolute;
    right: 12px;
    top: 12px;
    width: 18px;
    z-index: 1;
}
.abfilter--cont.abfilter-cont-b {
    position: relative;
    display: block;
    padding: 18px 0 10px 15px;
    border-bottom: 1px solid $grey-43 !important;
    overflow-y: hidden;
    overflow-x: auto;
    p.abfilter--title {
        font-size: 15px;
        display: block;
        margin-bottom: 15px;
        margin-top: 0;
        line-height: 18px;
        color: #25292b;
        font-weight: 400;
    }
    .abfilter--scoller {
        position: relative;
        white-space: nowrap;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        padding-bottom: 10px;
    }
    .abfilter--scoller button {
        padding: 5px 8px;
        border: 1px solid #177a99;
        background: $white;
        border-radius: 2px;
        color: #177a99;
        font-size: 12px;
        margin-right: 8px;
        display: inline-block;
        font: 400 13.3333px Arial;
        line-height: normal;
    }
}
.abcardfilter.abcardfilter-bg {
    position: relative;
    display: block;
    background: #f1f7e6;
    border: solid 1px #d5e3ad;
    -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, .22);
    -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, .22);
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .22);
    z-index: 1;
    margin-top: -1px;
    border-left: 0;
    border-right: 0;
    .abcardfilter--cont.abcardfilter--cont-a {
        position: relative;
        display: block;
        padding: 16px;
        border-bottom: 1px solid $grey-43 !important;
    }
    .abcardfilter--cont .abcardfilter--icon {
        display: inline-block;
        width: 27%;
        vertical-align: middle;
    }
    .abcardfilter--text {
        display: inline-block;
        vertical-align: middle;
        padding-left: 16px;
        width: 70%;
    }
    p.abcardfilter--heading {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 8px;
        margin-top: 0;
    }
    p.abcardfilter--title {
        font-size: 14px;
        margin: 0;
    }
    a.abcardfilter-ctc {
        font-size: 14px;
        font-weight: 600;
        color: $yellow2 !important;
        margin-top: 8px;
        display: block;
    }
}
.askAnExpertouter.abcardfilter.abcardfilter--cont-c {
    position: relative;
    .askAnExpert.askAnExpertCategoryClick {
        background: url(https://static1.hkrtcdn.com/gritzo/static/media/category/Ask_An_Expert_background.svg);
        background-size: cover;
        height: 90px;
        position: relative;
        padding: 10px 16px;
        border-bottom: solid $grey-14 1px;
        margin-bottom: 10px;
    }
    .ask_icon {
        background: url(https://static1.hkrtcdn.com/gritzo/static/media/category/Ask_an_expert_icon.svg);
        width: 60px;
        height: 60px;
        background-repeat: no-repeat;
        float: left;
        cursor: pointer;
    }
    .ask_details {
        float: left;
        padding-left: 8px;
        margin-top: 5px;
        max-width: calc(100% - 60px);
        cursor: pointer;
    }
    .ask_advice_text {
        color: #25292b;
        font-weight: 600;
        width: 100%;
        float: left;
    }
    .ask_advice_text b {
        display: block;
        font-size: 16px;
        font-weight: bold;
    }
    .ask_number {
        float: left;
        font-weight: 600;
        color: $blue-3;
    }
}
.abcardfilter--cont.abcardfilter--cont-b {
    position: relative;
    display: block;
    padding: 16px;
    border-bottom: 1px solid $grey-43 !important;
    img.abcardfilter--icon.afi-w54 {
        padding: 3%;
    }
}
.filter-container-mob {
    position: fixed;
    height: 100%;
    overflow: hidden;
    top: 0;
    background-color: #f5f5f5;
    width: 100%;
    font-size: 14px;
    z-index: 111111;
    .filter-header {
        background-color: $yellow2 !important;
        height: auto;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        .header-name {
            height: 64px;
            .filter-by-text {
                float: left;
                color: $white;
                margin-top: 23px;
                margin-left: 20px;
                font-size: 18px;
                font-weight: 600;
                .left-arrow {
                    border: solid $white;
                    border-width: 0 2px 2px 0;
                    display: inline-block;
                    padding: 4px;
                    transform: rotate(135deg);
                    -webkit-transform: rotate(135deg);
                }
                .arrow-line {
                    display: inline-block;
                    width: 20px;
                    border-top: 2px solid $white;
                    padding-top: 4px;
                    margin-right: 11px;
                    margin-left: -11px;
                }
            }
        }
        .filter-search-bar {
            height: 60px;
            padding-left: 15px;
            padding-right: 15px;
            img {
                height: 18px;
                width: 18px;
                top: 75px;
                position: absolute;
                right: 28px;
            }
        }
        .filter-search-bar input[type=text] {
            width: 100%;
            border: none;
            border-radius: 4px;
            height: 42px;
            padding-left: 12px;
        }
    }
    .filter-content {
        width: 100%;
        top: 0;
        bottom: 52px;
        position: fixed;
        &:not(.hideOverflow) {
            -webkit-overflow-scrolling: touch;
            overflow-y: scroll;
        }
        div.slider {
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        }
        .price-range-selector {
            height: 110px;
            background: $white;
            .price-values {
                height: 60px;
                padding-top: 13px;
                .min-price {
                    float: left;
                    width: 25%;
                    margin-right: 20%;
                    margin-left: 5%;
                    text-align: left;
                    .min-price-value {
                        font-weight: 600;
                        font-size: 20px;
                    }
                }
                .max-price {
                    float: right;
                    width: 25%;
                    margin-left: 20%;
                    margin-right: 5%;
                    text-align: left;
                    .max-price-value {
                        font-weight: 600;
                        font-size: 20px;
                    }
                }
            }
            .range-slider-container {
                width: 100%;
                .slider {
                    -webkit-appearance: none;
                    width: 100%;
                    height: 15px;
                    border-radius: 5px;
                    background: #d3d3d3;
                    outline: none;
                    opacity: 0.7;
                    -webkit-transition: .2s;
                    transition: opacity .2s;
                }
                .slider:hover {
                    opacity: 1;
                }
                .slider::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    appearance: none;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    background: #4CAF50;
                    cursor: pointer;
                }
                .slider::-moz-range-thumb {
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    background: #4CAF50;
                    cursor: pointer;
                }
                .slider-min {
                    position: relative;
                }
                .slider-max {
                    position: absolute;
                    width: 100%;
                    top: 102px;
                }
                .slider-tp {
                    background: transparent;
                }
            }
        }
        .filter-brands-content {
            // max-height: 484px;
            top: 123px;
            bottom: 52px;
        }
        .filter-container-mob.pos-static,
        .filter-options-wrapper {
            padding: 10px 0;
            background: $white;
        }
        .filter-option {
            color: $black;
            font-size: 14px;
            font-weight: $semibold;
            background: $white;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .filter-title {
                font-weight: $semibold;
                color: $black;
                font-size: 14px;
            }
            .arrow-wrap {
                width: 16px;
                text-align-last: center;
            }
            .arrow-right {
                border: solid #676571;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 4px;
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
            }
            .arrow-right {
                border: solid #676571;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 4px;
                transform: rotate(136deg);
                -webkit-transform: rotate(136deg);
            }
            .arrow-down {
                border: solid #676571;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 4px;
                transform: rotate(43deg);
                -webkit-transform: rotate(43deg);
            }
            .arrow-up {
                border: solid #676571;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 4px;
                transform: rotate(225deg);
                -webkit-transform: rotate(225deg);
            }
        }
    }
}
.filter-footer {
    position: fixed;
    bottom: 10px;
    left: 0;
    text-align: center;
    width: 100%;
    line-height: 4;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    background: $white;
    .filter-apply {
        background: $yellow1;
        width: 50%;
        color: $black;
        font-size: 15px;
        font-weight: 600;
        border-radius: 2px;
        margin: 0 10px;
    }
    .filter-reset {
        margin: 0 10px;
        border-radius: 2px;
        background: $white;
        border: 1px $black3 solid;
        width: 50%;
        color: $black;
        font-size: 15px;
        font-weight: 600;
    }
}
.filter-second-layer {
    z-index: 222222;
}
.checkBoxLi {
    background: $white;
    .checkbox-container {
        float: right;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        .checkmark {
            position: absolute;
            top: -1px;
            left: 0;
            height: 24px;
            width: 24px;
            background-color: $white;
            border-radius: 3px;
            border: 2px solid #000000a6;
        }
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }
        .checkmark:after {
            left: 8px;
            top: 2px;
            width: 6px;
            height: 13px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
    .checkbox-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .checkbox-container input:checked+.checkmark {
        background-color: $yellow2;
        border: 0;
    }
    .checkbox-container input:checked+.checkmark:after {
        display: block;
    }
    .checkbox-container .checkmark:after {
        left: 8px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}
.filter-li-disabled {
    height: 55px;
    background-color: #bbbbbb4d;
    opacity: 0.5;
    color: #4a4d56;
    pointer-events: none;
}
.filter-list {
    margin-bottom: 30px;
}
.radioLi {
    background: $white;
    line-height: auto;
    &.border-bottom {
        border-bottom: 1px $grey-48 solid;
        padding-bottom: 10px;
        margin-bottom: 10px;
        &:last-child {
            border-bottom: 0;
            margin-bottom: 0;
        }
    }
    .radio-container {
        float: left;
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: $white;
            border-radius: 50%;
            border: 2px solid #00000087;
        }
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }
        .checkmark:after {
            top: 9px;
            left: 9px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: $yellow2;
        }
    }
    .radio-container input {
        position: absolute;
        opacity: 0;
    }
    .radio-container input:checked+.checkmark {
        background-color: $white;
        border: 2px solid $yellow2;
    }
    .radio-container input:checked+.checkmark:after {
        display: block;
    }
    .radio-container .checkmark:after {
        left: 3px;
        top: 3px;
        width: 12px;
        height: 12px;
        border: solid $yellow2;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}
/*min max slider css start*/
.filter-container-mob {
    .filter-list {
        margin-bottom: 0;
    }
    .filter-content {
        .price-range-selector {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            .PriceFields {
                width: 100%;
                float: left;
                margin-bottom: 10px;
                >div {
                    float: left;
                    &.max-price {
                        float: right;
                    }
                    >div {
                        display: block;
                        clear: both;
                        font-size: 12px;
                        &.pricevalue {
                            font-size: 17px;
                            font-weight: bold;
                            font-family: $fontRoboto;
                        }
                    }
                }
            }
            .slider {
                width: 100%;
                float: left;
                .handle {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background: $white;
                    border: 2px $grey-10 solid;
                    @include set-box-shadow(0, 3px, 6px, $grey-31);
                }
                .bar {
                    background: $grey-10;
                    height: 3px;
                    top: 8px;
                    border-radius: 20px;
                    &.bar-1 {
                        background: $yellow2;
                    }
                }
            }
        }
        .slider-disabled {
            .slider {
                pointer-events: none;
                .handle {
                    background: $grey-10;
                    @include set-box-shadow(0, 3px, 6px, $grey-31);
                }
                .bar {
                    &.bar-1 {
                        background: $grey-10;
                    }
                }
            }
        }
    }
}
.hk-variants.hk-boxVar-desk {
    .vrnt-rvw-cnt {
        margin-top: -155px !important;
        z-index: 11 !important;
    }
    .hk-variants-box-head {
        display: inline-block;
        width: 100%;
        max-height: 35px;
        box-sizing: content-box;
        .falshsale-img {
            display: inline-block;
            vertical-align: middle;
            width: 35%;
            text-align: left;
            >img {
                width: 75%;
                max-width: 70px;
            }
        }
        .hk-variants-flashsale-timer {
            width: 65%;
            display: inline-block;
            vertical-align: text-bottom;
            text-align: right;
            .units {
                text-align: left;
                width: 55%
            }
            >span {
                font-weight: 600;
                color: #424242;
                font-size: 12px;
                display: inline-block;
                width: 45%;
            }
        }
    }
    .hk-variants-box-body {
        position: relative;
        .hk-variants-box-body-hov-vis {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 205px;
            border-radius: 4px;
            -webkit-backdrop-filter: blur(6.9px);
            background-color: rgba(0, 0, 0, 0.8);
            z-index: 1;
            >div {
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 10px;
            }
            .hk-variants-supp-info {
                margin-top: 15px;
            }
            .hk-variants-consume-info {
                display: block;
                >span {
                    display: inline-block;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 13px;
                    letter-spacing: normal;
                    text-align: left;
                    color: $white;
                    width: 100%;
                }
                >ul {
                    >li {
                        color: $white;
                        font-size: 11px;
                        letter-spacing: normal;
                        text-align: left;
                        float: left;
                        width: 50%;
                        padding: 3px 5px 3px 8px;
                        position: relative;
                        >.li-bullets {
                            width: 4px;
                            height: 4px;
                            background: $white;
                            border-radius: 50%;
                            position: absolute;
                            left: 0;
                            top: 9px;
                        }
                    }
                }
            }
        }
    }
    display: inline-block;
    width: 100%;
    .variant-link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        @media screen and (min-width: 768px) {
            z-index: 2;
        }
    }
    .hk-variants-box-body-vis {
        .wishlist {
            right: 10px;
            top: 10px !important;
        }
        .variant-name {
            font-size: 16px;
            line-height: 22px;
            letter-spacing: normal;
            text-align: left;
            color: #212121;
            white-space: normal;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .variant-desc {
            margin: 2px 0 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            >img {
                vertical-align: middle;
                margin-right: 6px;
            }
            >span {
                font-size: 12px;
                line-height: 1.17;
                letter-spacing: normal;
                text-align: left;
                color: #616161;
                vertical-align: middle;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
        .hk-variants-offer-sec {
            padding-top: 5px;
            border-top: 1px dashed $grey-39;
            margin-top: 10px;
            margin-bottom: 20px;
            .hk-variants-freebie {
                display: inline-block;
                width: 30%;
                text-align: left;
                position: absolute;
                top: 30px;
                >img {
                    width: 15px;
                    margin-right: 5px;
                }
                >span {
                    font-size: 12px;
                    line-height: 1.17;
                    letter-spacing: normal;
                    text-align: left;
                    color: #616161;
                }
            }
            .hk-variants-bxgy {
                display: inline-block;
                width: 65%;
                text-align: left;
                >img {
                    width: 15px;
                    margin-right: 5px;
                    vertical-align: middle;
                }
                >span {
                    font-size: 12px;
                    line-height: 1.17;
                    letter-spacing: normal;
                    text-align: left;
                    color: #616161;
                    vertical-align: middle;
                }
            }
            .hk-variants-more-offer {
                font-size: 12px;
                font-weight: 600;
                line-height: 1.17;
                letter-spacing: normal;
                text-align: left;
                color: #4caf50;
                display: block;
                width: 100%;
            }
            .hk-variants-freebie+.hk-variants-bxgy+.hk-variants-more-offer {
                text-align: left;
            }
            .hk-variants-bxgy+.hk-variants-more-offer {
                text-align: right;
            }
            .hk-variants-more-offer-hid {
                margin-top: 15px;
            }
            @media screen and (min-width: 768px) {
                margin-top: 5px;
                .hk-variants-bxgy {
                    padding-left: 0;
                    span {
                        background: #E5601A;
                        color: $white;
                        font-size: 11px;
                        padding: 2px 4px;
                        border-radius: 2px;
                        font-size: 10px;
                    }
                }
            }
        }
        .hk-variants-more-offer {
            background: green;
            color: $white;
            padding: 2px 5px;
            max-width: 70px;
            text-align: center;
            text-transform: uppercase;
            font-size: 11px;
            border-radius: 2px;
            position: absolute;
            z-index: 1;
        }
    }
}
.know-more-category {
    position: absolute;
    right: 10px;
    text-align: right;
    line-height: 10px;
    color: $yellow2;
    font-family: Roboto;
    font-size: 14px;
    z-index: 2;
    white-space: nowrap;
    background: $white;
    display: inline-block;
    float: right;
    padding-top: 3px;
}
.single-line-ellipses {
    color: #424242;
    height: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 60%;
    font-family: Roboto;
    display: inline-block !important;
}
// Review slider css
.review-slider-widget {
    float: left;
    width: 100%;
    padding: 0 15px;
    margin: 10px 0;
    .content-widget-heading {
        .review-sc-head {
            text-align: left;
            font-size: 22px;
            font-weight: 100;
        }
    }
    .owl-carousel {
        margin-top: 15px;
        .owl-item {
            .review-slider-itm {
                display: inline-block;
                padding: 10px 5px;
                height: 130px;
                border: 1px solid rgba(0, 0, 0, 0.05);
                border-radius: 5px;
                width: 100%;
                .review-pd-img {
                    width: 28%;
                    float: left;
                }
                .review-pd-cont {
                    float: right;
                    width: 68%;
                    text-align: left;
                    font-weight: 600;
                    .product-name {
                        font-size: 13px;
                        font-weight: 400;
                        margin-top: 0;
                        margin-bottom: 4px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .review-txt {
                        margin: 0;
                    }
                    .reveiwer-name {
                        font-size: 13px;
                        font-weight: 400;
                        margin-bottom: 0;
                        height: 21px;
                        overflow: hidden;
                        margin-top: 4px;
                    }
                    .review-stars {
                        display: inline-block;
                        width: 70px;
                        height: 14px;
                        background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/common/misc/star-empty.svg);
                        background-repeat: repeat-x;
                        margin-right: 6px;
                        .rtng-usr {
                            // display: inline-block;
                            height: 14px;
                            background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/common/misc/star-filled.svg);
                            background-repeat: repeat-x;
                            vertical-align: top;
                        }
                    }
                }
            }
        }
    }
    .owl-dots span {
        width: 8px !important;
        height: 8px !important;
        margin: 0 5px !important;
        border: 1px solid $grey-14;
        display: block;
        -webkit-backface-visibility: visible;
        @include transition(opacity .2s ease);
        border-radius: 30px;
        box-sizing: border-box;
        background: $white !important;
    }
    .owl-dot.active {
        span {
            background: $primary-color !important;
            border-color: $primary-color;
        }
    }
    .owl-dots {
        position: static;
    }
    .owl-nav {
        margin: 0 !important;
    }
}
@media screen and (max-width: 768px) {
    .review-slider-widget {
        padding: 0 10px;
        margin: 0;
        .review-list-mobile {
            display: inline-block;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            .review-slider-itm {
                display: inline-block;
                padding: 10px 5px;
                height: 130px;
                border: 1px solid rgba(0, 0, 0, 0.05);
                border-radius: 5px;
                max-width: 250px;
                margin-right: 10px;
                .review-pd-img {
                    width: 28%;
                    float: left;
                    margin: 1%;
                }
                .review-pd-cont {
                    float: right;
                    width: 68%;
                    text-align: left;
                    font-weight: 600;
                    .product-name {
                        font-size: 13px;
                        font-weight: 400;
                        margin-top: 0;
                        margin-bottom: 4px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .review-txt {
                        margin: 0;
                        white-space: initial;
                    }
                    .reveiwer-name {
                        font-size: 13px;
                        font-weight: 400;
                        margin-bottom: 0;
                        height: 21px;
                        overflow: hidden;
                        margin-top: 4px;
                    }
                    .review-stars {
                        display: inline-block;
                        width: 70px;
                        height: 14px;
                        background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/common/misc/star-empty.svg);
                        background-repeat: repeat-x;
                        margin-right: 6px;
                        .rtng-usr {
                            height: 14px;
                            background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/common/misc/star-filled.svg);
                            background-repeat: repeat-x;
                            vertical-align: top;
                        }
                    }
                }
            }
        }
    }
    // chandesk chat launcher position
    .plpPage iframe#launcher {
        right: 40px !important;
        bottom: 42px !important;
    }
}
// loyality css
.cat-page {
    .hk-variants.hk-boxVar-desk.loyality-cat {
        .hk-variants-box-container {
            .hk-variants-box-body {
                .hk-variants-offer-sec {
                    border-top: none;
                    .delivery-cont {
                        display: inline-block;
                        width: 100%;
                        * {
                            font-size: 12px;
                        }
                        .left {
                            float: left;
                        }
                        .right {
                            float: right;
                        }
                        .green {
                            color: #00880A;
                        }
                    }
                }
            }
            span.timer {
                color: #F05300 !important;
            }
            .hk-variants-box-head {
                .hk-variants-flashsale-timer {
                    .units {
                        text-align: right;
                    }
                }
            }
            .hk-variants-box-body {
                .hk-variants-box-body-hov-vis {
                    background-color: $blue-3;
                    >div {
                        top: 20px;
                        .hk-variants-consume-info:first-child {
                            border-bottom: 1px solid #90909029;
                            padding-bottom: 12px;
                        }
                    }
                    .hk-variants-supp-info {
                        margin-top: 8px;
                        line-height: 11px;
                        padding-bottom: 8px;
                    }
                    .hk-variants-consume-info {
                        .hk-variants-consume-info-1 {
                            &.ingr {
                                img {
                                    width: 16px;
                                }
                            }
                            &.effcacy {
                                img {
                                    width: 17px;
                                }
                            }
                            &.mixablity {
                                img {
                                    width: 16px;
                                    // margin-right: 10px;
                                    position: relative;
                                    top: 4px;
                                    vertical-align: bottom;
                                }
                            }
                        }
                        >ul {
                            display: inline-block;
                            width: 100%;
                            >li {
                                width: 25%;
                                padding: 0 5px 0 0;
                                line-height: 14px;
                                >.li-bullets {
                                    width: 0;
                                    height: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .mob-cat2 .mobcatSlider .variant-slider .hk-variants.loyality-cat .hk-variants-box-container .hk-variants-box-body {
        // @media screen and (max-width: 1025px){
        min-height: unset !important;
        // }
    }
    .hk-variants.hk-boxVar-desk.loyality-cat,
    .hk-variants.hk-variants-box.loyality-cat {
        .hk-variants-box-container .hk-variants-box-body {
            .hk-variants-price {
                &.prem {
                    color: #1E3F88;
                }
                .icon {
                    vertical-align: top;
                    margin-right: 5px;
                    width: 16px;
                }
            }
        }
        // Premium lock card
        .hk-premium-price {
            width: 100%;
            display: inline-block;
            position: relative;
            left: 0;
            padding: 5px 7px;
            border: 1px solid rgb(202, 250, 250);
            background: #ECF5F8;
            margin-bottom: 5px;
            border-radius: 2px;
            cursor: pointer;
            z-index: 2;
            @media screen and (max-width: 768px) {
                padding: 0 10px;
                width: 80%;
                z-index: 1;
                min-width: 175px;
            }
            @media screen and (max-width: 350px) {
                width: 100%;
            }
            img.icon {
                width: 16.5px;
                vertical-align: bottom;
                position: absolute;
                margin-top: 3px;
                @media screen and (max-width: 768px) {
                    top: 3px;
                    width: 14px;
                    margin-top: 0;
                }
            }
            img.locked {
                display: inline-block;
                width: 10px;
                cursor: pointer;
            }
            .msg {
                width: calc(100% - 30px);
                position: relative;
                left: 24px;
                @media screen and (max-width: 370px) {
                    width: calc(100% - 20px);
                    left: 20px;
                }
                span {
                    font-size: 15px;
                    color: rgb(30, 63, 136);
                    @media screen and (max-width: 370px) {
                        font-size: 14px;
                    }
                }
                .bold {
                    font-weight: bold;
                }
            }
            .left {
                float: left;
                width: calc(100% - 10px);
            }
            .right {
                float: right;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .hk-variants.hk-variants-box.loyality-cat {
            .hk-variants-consume-info {
                padding: 7px 0 16px 9px;
                color: #292929;
                opacity: 0.58;
                // margin-top: 17px;
                .hk-variants-supp-info--title {
                    font-size: 12px;
                    margin-bottom: 3px;
                    display: inline-block;
                    width: auto; //48%;
                }
                hr {
                    border: 0;
                    height: 1px;
                    background: #70707017;
                    display: inline-block;
                    width: calc(100% - 186px); //48%;
                    vertical-align: middle;
                    margin-right: 0;
                }
                ul {
                    display: flex;
                    // flex-flow: wrap;
                    white-space: nowrap;
                    overflow-y: auto;
                    width: 100%;
                    padding: 5px 0;
                    li {
                        &:first-child {
                            padding: 0;
                            padding-right: 15px;
                        }
                        &:last-child {
                            border-right: 0;
                            padding-right: 0;
                        }
                        display: inline-block;
                        padding-left: 15px;
                        padding-right: 15px;
                        border-right: 1px solid #7070705c;
                        font-size: 12px;
                    }
                }
                .hk-variants-consume-info-2 {
                    span {
                        font-size: 11px;
                        line-height: 22px;
                    }
                    .hk-variants-consume-info-3 {
                        margin-right: 10px;
                        border-right: 1px solid #7070705c;
                        padding-right: 10px;
                    }
                    .single-line-ellipses {
                        width: auto;
                    }
                    .know_more {
                        color: #00B7BA;
                        font-weight: 500;
                    }
                }
            }
            .hk-variants-box-footer {
                border-top: 1px solid #70707017;
                padding: 5px 10px;
            }
            .hk-variants-box-head {
                background: none;
                padding: 0 23px 0 0;
                background: $white !important;
                .variant-name {
                    white-space: normal;
                    -o-text-overflow: unset;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                }
                .wishlist {
                    top: 2px !important;
                }
            }
            .hk-variants-box-head .variant-desc {
                margin-top: 7px;
                margin-bottom: 0;
            }
            .variant-rating-count {
                margin-top: 7px;
                z-index: 1;
                .review-stars {
                    display: inline-block;
                    width: 70px;
                    height: 14px;
                    background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/common/misc/star-empty.svg);
                    background-repeat: repeat-x;
                    margin-right: 6px;
                    .rtng-usr {
                        display: inline-block;
                        height: 14px;
                        background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/common/misc/star-filled.svg);
                        background-repeat: repeat-x;
                        vertical-align: top;
                    }
                }
                .review-count {
                    display: inline-block;
                    font-size: 12px;
                    // vertical-align: top;
                }
            }
            .hk-variants-box-container .hk-variants-box-body .hk-variants-box-body--right {
                padding-left: 116px;
                .hk-variants-bxgy {
                    background: #E5601A;
                    color: $white;
                    font-size: 11px;
                    padding: 2px 4px;
                    border-radius: 2px;
                }
                .hk-variants-price {
                    font-size: 15px;
                    margin: 8px 0 12px 0;
                    .variant-offer {
                        font-size: 15px !important;
                        color: #37BE5F;
                        border: none;
                        float: none;
                    }
                    .icon {
                        vertical-align: bottom;
                        margin-right: 5px;
                    }
                }
                .variant-old-price {
                    font-size: 15px !important;
                    font-weight: 500;
                }
                .variant-offer {
                    font-size: 15px !important;
                }
                .hk-variants-flashsale-timer {
                    span {
                        font-size: 13px;
                        font-weight: 400;
                        color: #000000;
                        &.timer {
                            color: #F05300;
                        }
                    }
                }
            }
            .compare-cont {
                margin-top: 7px;
                position: relative;
                z-index: 1;
                height: 21px;
                .variant-box-compare-icon {
                    margin-left: 11px;
                    height: 16px;
                    bottom: 0;
                    .compare-icon-inner {
                        width: 11px;
                        height: 11px;
                        top: 0;
                    }
                    .compare-icon-inner.overlap {
                        box-shadow: unset;
                        top: -3px;
                        bottom: 0;
                        right: 0;
                        left: -3px;
                    }
                }
                span {
                    position: absolute;
                    margin-left: 29px;
                    &.green_text {
                        color: #37BE5F;
                        font-weight: bold;
                        margin-left: 0;
                    }
                    img {
                        position: relative;
                        top: 1px;
                    }
                }
                .hkcash-vba {
                    position: absolute;
                    margin-left: 118px;
                }
            }
        }
    }
    // Loyality banner
    .banners-loyality {
        display: block;
        margin: 12px 10px;
        @media screen and (min-width: 768px) {
            width: 100%;
            max-width: 1170px;
            float: none;
            margin: 0 auto;
            clear: both;
            // min-width: 1025px;
            margin-bottom: 15px;
            display: inline-block;
            width: 100%;
            margin-top: 15px;
        }
        img {
            width: 100%;
            border-radius: 5px;
        }
        &.strip_banner {
            img {
                max-height: 140px;
            }
        }
        &.super_savings {
            img {
                max-height: 175px;
            }
        }
    }
}
.hk-variants.hk-boxVar-desk .hk-variants-box-body-vis .wishlist img {
    width: 20px;
    height: auto;
}
.overflow {
    overflow: auto;
    width: 100%;
    max-height: 300px;
}
/* category page */
.category-page {
    .var-info-wrap {
        float: left;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .hk-variants-supp-info--title {
            display: block;
            color: $black;
            font-size: 14px;
        }
        .var-contains-list {
            margin-top: 5px;
            li {
                position: relative;
                padding-left: 10px;
                margin-bottom: 1px;
                font-size: 13px;
                line-height: normal;
                span {
                    font-size: 13px;
                    line-height: normal;
                }
                &:before {
                    position: absolute;
                    top: 5px;
                    left: 0;
                    width: 6px;
                    height: 6px;
                    border-radius: 100%;
                    content: '';
                    background: $black2;
                }
            }
        }
        .certified-lab {
            img {
                margin-right: 10px;
            }
        }
    }
    .hk-variants.hk-boxVar-desk {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .hk-variants-box-container {
        width: 100%;
    }
    .variant-tile.js-varnt-tile {
        min-height: 0px;
        max-height: none;
        height: auto;
    }
    .hk-variants-box-body-vis {
        display: flex;
        width: 100%;
    }
}
.view-cat-info {
    border: 1px $grey-8 solid;
    border-radius: 3px;
    margin: 20px 0;
    a {
        color: $black;
        font-size: 13px;
        width: 100%;
        position: relative;
        display: block;
        padding: 0 30px 0 10px;
        line-height: 40px;
        white-space: nowrap;
        img {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
.AddToCartnew,
.goToCartnew {
    font-size: 14px;
    font-weight: 600;
    line-height: 0.86;
    letter-spacing: normal;
    color: $white;
    text-align: center;
    border: 0;
}
.category-action {
    display: flex;
    .hk-elements--addToCart {
        margin-right: 10px;
    }
}
.hk-variants-price {
    margin: 20px 0 8px 0;
    .variant-price {
        font-size: 20px;
        font-weight: bold;
        line-height: 1.19;
        letter-spacing: normal;
        text-align: left;
        color: $blue-1;
        margin-right: 15px;
    }
    .variant-old-price {
        font-size: 16px;
        font-weight: normal;
        line-height: 1.19;
        letter-spacing: normal;
        text-align: left;
        color: $grey-37;
        margin-right: 15px;
        text-decoration: line-through;
    }
    .variant-offer {
        display: block;
        font-size: 14px;
        line-height: 1.14;
        text-align: left;
        color: $orange6;
        margin-bottom: 10px;
    }
}
.cat-features {
    .feature-section {
        float: left;
        width: 100%;
        margin: 10px 0;
        overflow: hidden;
        .feature-item {
            display: inline-block;
            text-align: left;
            width: auto;
            padding: 0 8px;
            &>div {
                text-align: center;
                letter-spacing: normal;
            }
            img {
                height: 20px;
            }
            div {
                margin-top: 5px;
                color: black;
                font-size: 9px;
                text-transform: uppercase;
                letter-spacing: 2.64px;
                line-height: normal;
                font-weight: 500;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
.variant-rating-count {
    margin-top: 8px;
    margin-top: 5px;
    clear: both;
    .review-stars {
        display: inline-block;
        width: 70px;
        height: 14px;
        background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/common/misc/star-empty.svg);
        background-repeat: repeat-x;
        margin-right: 6px;
        .rtng-usr {
            display: inline-block;
            height: 14px;
            background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/common/misc/star-filled.svg);
            background-repeat: repeat-x;
            vertical-align: top;
        }
    }
    .review-count {
        display: inline-block;
        font-size: 12px;
        vertical-align: top;
    }
}
.hk-variants-box-footer {
    position: relative;
    >.hk-variants-cta {
        display: flex;
        .AddToCartnew,
        .goToCartnew {
            font-size: 14px;
            font-weight: 600;
            letter-spacing: normal;
            color: $white;
            text-align: center;
            border: 0;
            background-color: $black10;
            border-radius: 4px;
            white-space: nowrap;
            padding-left: 20px;
            padding-right: 20px;
            line-height: 33px;
            margin-right: 5px;
            &:hover {
                color: $white
            }
            >img {
                vertical-align: top;
                height: 10px !important;
                padding-left: 3px;
            }
        }
        .buyNownew {
            @extend .AddToCartnew;
            color: $black;
            margin-left: 5px;
            margin-right: 0px;
            background-color: $yellow1;
            color: $black;
            &:hover {
                color: $black;
            }
        }
    }
}
.breadcrumb-category {
    margin-bottom: 20px;
}
.price-range-lbl {
    color: $black;
    font-size: 12px;
    font-weight: $semibold;
    line-height: 17px;
    padding: 15px 0;
}
.cat-page {
    background: $grey-0;
}

.cat-page {
    .fnt-ttl {
        font-size: 28px;
        line-height: 37px;
        color: $black7;
        font-weight: $semibold;
    }
    .bnr-main-cnt {
        float: left;
        width: 100%;
    }
    .category-top-bar {
        .label-text {
            font-size: 24p;
            color: $black;
            font-weight: $medium;
        }
        .label-value {
            font-size: 24p;
            color: $grey-47;
            font-weight: normal;
        }
    }
    .variant-img-wrap {
        margin-right: 10px;
        width: 200px;
        position: relative;
        .variant-img {
            min-height: 100px;
            height: auto;
            position: relative;
            img {
                width: 180px;
                max-width: none;
                height: auto;
                max-height: none !important;
            }
            .offerCircle {
                position: absolute;
                background: $orange;
                color: $white;
                width: 50px;
                height: 50px;
                border-radius: 100%;
                top: 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                &.right {
                    right: 0px;
                }
                &.left {
                    left: 15px;
                }
                span {
                    display: block;
                    color: $white;
                    font-size: 14px;
                    line-height: normal;
                    font-weight: 700;
                }
                span.off {
                    font-weight: normal;
                    font-size: 12px;
                }
            }
        }
        a {
            display: block;
        }
    }
    .banner-cntr {
        img {
            max-width: 100%;
            width: 100%;
        }
    }
}
.page-layout-mob {
    .cat-page {
        .variant-img-wrap {
            width: 160px;
            position: relative;
            .img-info {
                font-size: 12px;
            }
            .variant-img {
                position: relative;
                min-height: 100px;
                height: auto;
                img {
                    max-width: 100%;
                }
                .offerCircle {
                    position: absolute;
                    background: $orange;
                    color: $white;
                    width: 42px;
                    height: 42px;
                    border-radius: 100%;
                    top: 0px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    &.right {
                        right: 0px;
                    }
                    &.left {
                        left: 15px;
                    }
                    span {
                        display: block;
                        color: $white;
                        font-size: 12px;
                        line-height: normal;
                    }
                }
            }
            a {
                display: block;
            }
        }
    }
}
.img-info {
    color: $black;
    padding-bottom: 5px;
    border-bottom: 1px dashed $grey-10;
    font-size: 15px;
    display: inline-block;
}
.sold-out-box {
    padding-bottom: 20px;

    label {
        font-weight: 600;
    }
}
.nutrition-img-container {
    p>img {
        width: 100% !important;
        height: auto !important;
    }
}
.filter-box .collapsible-box {
    .radioBox label {
        margin-bottom: 5px;
    }
    .radioBox span.checboximg {
        top: 4px
    }
}
@media screen and (max-width: 350px) {
    .hk-variants-box-footer {
        .hk-variants-cta {
            .AddToCartnew,
            .goToCartnew {
                font-size: 12px;
                padding-left: 5px !important;
                padding-right: 5px !important;
                line-height: 33px;
                margin-right: 5px;
            }
        }
    }
}
.product-flv {
    font-size: 13px;
}