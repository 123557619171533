$color-base: rgb(231, 231, 231);
$color-highlight: lighten($color-base, 3%);

@mixin skeletonDefault ($width, $height) {
    width: $width;
    height: $height;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    background: $color-base;
    animation: flickering 2s ease-in-out infinite;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, $color-base, $color-highlight, $color-base);
        animation: progress 1s ease-in-out infinite;
      }
}

@keyframes flickering {
    0% {
      opacity: .3;
    }
    50% {
        opacity: 1;
      }
    100% {
      opacity: .3;
    }
  }

@keyframes progress {
    0% {
      transform: translate3d(-100%, 0, 0);
    }
    100% {
      transform: translate3d(100%, 0, 0);
    }
  }

.pdp-skeleton{
    .pskel-breadcumbs-cont{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .pskel-breadcumbs{
            @include skeletonDefault(35%, 27px);
            margin: 20px 0 10px 0;
        }
        .pskel-pincode{
            @include skeletonDefault(51%, 27px);
            margin: 20px 0 10px 0;
            text-align: left;
        }
    }
    .pskel-image-cont{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .pskel-image-left{
            width: 44%;
            .pskel-image{
                @include skeletonDefault(515px, 503px);
                margin: 20px 0;
            }
            .pskel-small-images{
                width: 515px;
                display: flex;
                justify-content: space-between;
                .pskel-small-img{
                    @include skeletonDefault(77px, 70px);
                }
            }
        }
        .pskel-image-right{
            width: 51%;
            .pskel-catname{
                @include skeletonDefault(150px, 22px);
                margin: 20px 0;
            }
            .pskel-name{
                @include skeletonDefault(597px, 40px);
                margin: 10px 0;
            }
            .pskel-name-2{
                @include skeletonDefault(300px, 40px);
                margin: 10px 0;
            }
            .pskel-rating{
                @include skeletonDefault(160px, 18px);
                margin: 10px 0;
            }
            .pskel-mrp{
                @include skeletonDefault(90px, 18px);
                margin: 5px 0;
            }
            .pskel-price{
                @include skeletonDefault(230px, 26px);
                // margin: 10px 0;
            }
            .pskel-taxes{
                @include skeletonDefault(130px, 20px);
                margin: 5px 0;
            }
            .pskel-content-certs{
                width: 100%;
                margin: 24px 0 0 0;
                display: flex;
                justify-content: space-between;
                .pskel-certs-img{
                    @include skeletonDefault(98px, 98px);
                }
            }
            .pskel-flv-wt{
                @include skeletonDefault(140px, 20px);
                margin: 30px 0 10px 0;
            }
            .pskel-flv-wt-cont{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                .pskel-flv-wt-item{
                    @include skeletonDefault(135px, 40px);
                    margin: 5px 10px 5px 0;
                }
                .pskel-flv-wt-item-1{
                    @include skeletonDefault(180px, 40px);
                    margin: 5px 10px 5px 0;
                }
            }
        }
    }
}

.cart-skeleton{
    .cart-image-cont{
        width: 100%;
        display: flex;
        justify-content: space-between;
        .cart-image-left{
            width: calc(55% - 15px);
            .cart-image{
                @include skeletonDefault(612px, 229px);
                background-color: white !important;
                margin: 20px 0;
            }
            .cart-small-images{
                margin: 20px 0;
                width: 612px;
                display: flex;
                justify-content: space-between;
                .cart-small-img{
                    @include skeletonDefault(77px, 20px);
                }
            }
            .cart-cart{
                border: 1px solid #f2f2f2;
                height: 229.5px;
                width: 612.5px;
                border-radius: 4px;
                margin: 20px 0;
                .cart-cart-left{
                    .cart-content{
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        .image{
                            @include skeletonDefault(186px,186px);
                            margin: 20px 16px;
                        }
                        .desc{
                            margin: 20px 16px;
                            .line-1{
                                @include skeletonDefault(329.5px, 20px);
                                margin: 5px 10px 5px 0;
                            }
                            .line-2{
                                @include skeletonDefault(150px, 20px);
                                margin: 5px 10px 5px 0;
                            }
                            .line-3{
                                @include skeletonDefault(200px, 22px);
                                margin: 5px 10px 5px 0;
                            }
                            .line-4{
                                @include skeletonDefault(230px, 18px);
                                margin: 5px 10px 5px 0;
                            }
                            .line-5{
                                @include skeletonDefault(190px, 15px);
                                margin: 5px 10px 5px 0;
                            }
                            .buttons{
                                width: 100%;
                                display: flex;
                                flex-wrap: wrap;
                                .quantity{
                                    @include skeletonDefault(135px, 40px);
                                    margin: 5px 10px 5px 0;
                                }
                                .wishlist-button{
                                    @include skeletonDefault(180px, 40px);
                                    margin: 5px 10px 5px 0;
                                }
                            }
                        }
                    }
                }
                .cart-cart-right{
                    width: 30%;
                    .name {
                        @include skeletonDefault(150px, 22px);
                        margin: 0 0 5px 0;
                    }
                }   
            }
            .progress-bar{
                background-color: #FFFFFF;
                margin: 40px 16px;
                position: relative;
                    .horizontal-rule{
                        @include skeletonDefault(100%, 1px );
                        position: absolute;
                        top: 16px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .start{
                            width: 8px;
                            height: 8px;
                            background: #CCD1D9;
                            border-radius: 50%;
                        }
                    }
                    .steps{
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 92px;
                            @media screen and (max-width:768px){
                            padding: 0 36px;
                        }
                        .step{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            z-index: 1;
                            @include skeletonDefault(33px,33px );
                            border-radius: 50%;
                        }
                    }
                
            }
            .image2{
                @include skeletonDefault(612px,207px);
                margin: 20px 16px;
            }
            .combo{
                @include skeletonDefault(136px, 20px);
                margin: 20px 0px 0px 20px;
            }
            .small-images{
                margin: 20px 0px 0px 20px;
                width: 515px;
                display: flex;
                justify-content: space-between;
                .small-img{
                    @include skeletonDefault(77px, 20px);
                    align-items: flex-start;
                }
            }
        }
        .cart-image-right{
            width: calc(45% - 15px);
            padding: 5px 0px 5px 36px;
            margin-top: 86px;
            .cart-order-summary{
                @include skeletonDefault(200px, 39px);
                margin: 20px 0;
            }
            .cart-content-certs{
                width: 100%;
                margin: 24px 0 0 0;
                display: flex;
                justify-content: space-between;
                .pskel-certs-img{
                    @include skeletonDefault(98px, 98px);
                }
            }
            .cart-flv-price-cont{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                .cart-flv-price-item{
                    @include skeletonDefault(37px, 20px);
                    margin: 5px 10px 5px 0;
                }
                .cart-flv-price-item-1{
                    @include skeletonDefault(90px, 20px);
                    margin: 5px 10px 5px 270px;
                }
            }
            .cart-flv-price-cont{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                .cart-flv-price-item{
                    @include skeletonDefault(37px, 20px);
                    margin: 5px 10px 5px 0;
                }
                .cart-flv-price-item-1{
                    @include skeletonDefault(90px, 20px);
                    margin: 5px 10px 5px 315px;
                }
            }
            .delivery-fees-cont{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                .delivery-fees-cont-1{
                    @include skeletonDefault(90px, 20px);
                    margin: 5px 10px 5px 0;
                }
                .delivery-fees-cont-2{
                    @include skeletonDefault(40px, 20px);
                    margin: 5px 10px 5px 312px;
                }
            }
            .discount-cont{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                .discount-cont-1{
                    @include skeletonDefault(90px, 22px);
                    margin: 5px 10px 5px 0;
                }
                .discount-cont-2{
                    @include skeletonDefault(53px, 20px);
                    margin: 5px 10px 5px 299px;
                }
            }
            .total-amount-cont{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                .total-amount-cont-1{
                    @include skeletonDefault(98px, 20px);
                    margin: 5px 10px 5px 0;
                }
                .total-amount-cont-2{
                    @include skeletonDefault(48px, 20px);
                    margin: 5px 0px 5px 298px;
                }
            }
            .mbcash{
                @include skeletonDefault(486px, 42px);
                margin: 20px 0;
            }
            .checkout{
                @include skeletonDefault(486px, 42px);
                margin: 20px 0;
            }
            .apply-offer{
                @include skeletonDefault(109px, 20px);
                margin: 50px 0;
            }
            .apply-offer-placeholder{
                @include skeletonDefault(486px, 56px);
                margin: 20px 0;
            }
            .view-all{
                @include skeletonDefault(136px, 20px);
                margin: 20px 0;
            }
        }
    }
}

.mob-pdp-skeleton{
    padding: 0 16px;
    .mpskel-pincode{
        @include skeletonDefault(calc(100% + 32px), 66px);
        margin: 0 -16px;
    }
    .mpskel-catname{
        @include skeletonDefault(90px, 17px);
        margin: 20px 0 10px 0;
    }
    .mpskel-name{
        @include skeletonDefault(80%, 30px);
        margin: 0 0 5px 0;
    }
    .mpskel-name-2{
        @include skeletonDefault(100%, 30px);
        margin: 0 0 5px 0;
    }
    .mpskel-name-3{
        @include skeletonDefault(50%, 30px);
    }
    .mpskel-rating{
        @include skeletonDefault(40%, 18px);
        margin: 20px 0;
    }
    .mpskel-image{
        @include skeletonDefault(380px, 380px);
        margin: 0 auto;
        border-radius: 10px;
    }
    .mpskel-mrp{
        @include skeletonDefault(100px, 18px);
        margin: 30px 0 0 0;
    }
    .mpskel-price{
        @include skeletonDefault(200px, 20px);
        margin: 5px 0 0 0;
    }
    .mpskel-taxes{
        @include skeletonDefault(150px, 18px);
        margin: 5px 0 0 0;
    }
    .mpskel-content-certs{
        margin: 30px 0 0 0;
        display: flex;
        flex-wrap: wrap;
        .mpskel-certs-img{
            @include skeletonDefault(125px, 125px);
            border: 1px solid #f2f2f2;
        }
    }
}

.plp-skeleton-new{
    padding: 0;
    width: 100%;
    min-width: 918px;
    .plp-skeleton-new-cont{
        display: flex;
        flex-wrap: wrap;
        grid-column-gap: 20px;
        column-gap: 20px;
        grid-row-gap: 20px;
        row-gap: 20px;
        margin-bottom: 30px;
        .plp-skeleton-new-card{
            width: calc(50% - 10px) !important;
            border-radius: 4px;
            box-shadow: 0px 3px 20px rgba(57, 65, 73, 0.1019607843);
            @include skeletonDefault(calc(50% - 10px) !important, 206px);
        }
    }
}