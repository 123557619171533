@import '../common';

.cat-page {
    background: #FFFFFF !important;
}

.filter-header {
    margin-top: 20px;
    border-top: 0px;
}

.cat-list-right {
    padding-right: 10px;
    min-width    : 250px;
}

.sale-page-variant-cntr {
    display  : flex !important;
    flex-wrap: wrap;

    @media screen and (max-width:768px) {
        display: block !important;
    }

    &.fitt-food {
        flex-wrap      : wrap;
        grid-column-gap: 20px;
        column-gap     : 20px;
        grid-row-gap   : 20px;
        row-gap        : 20px;
        margin-bottom  : 30px;

        .hk-variants-cta {
            padding   : 9px 9px 0px 9px;
            border-top: 1px solid #ebecf1;
            display   : flex;

            .hk-elements--buyNow {
                width        : 50%;
                margin       : 0;
                text-align   : right;
                padding-right: 5px;

                button {
                    width         : 100%;
                    border        : none;
                    outline       : none;
                    background    : transparent;
                    border-radius : 2px;
                    height        : 40px;
                    font-family   : "Roboto";
                    font-size     : 14px;
                    font-weight   : 500;
                    line-height   : 17px;
                    letter-spacing: 0px;
                    color         : #080A08;
                }
            }

            .hk-elements--addToCart {
                button {
                    width         : 100%;
                    border        : none;
                    outline       : none;
                    background    : #080A08;
                    border-radius : 2px;
                    height        : 40px;
                    font-family   : "Roboto";
                    font-size     : 14px;
                    font-weight   : 500;
                    line-height   : 17px;
                    letter-spacing: 0px;
                    color         : #FFFFFF;
                    min-width     : 200px;
                    border-radius : 2px;
                }
            }
        }
    }

    .VPMw__text {
        top: -10px;
    }

    @media screen and (min-width: 768px) {

        >a {
            width        : calc(33.33% - 12px);
            margin-bottom: 12px;

            &:not(:nth-child(3n)) {
                margin-right: 16px;
            }
        }
    }
}

.vhd-header {
    @include heading1();
    margin: 16px 0;
}

.notifytext {
    color       : unset;
    font-size   : unset;
    float       : unset;
    padding-left: unset;
    line-height : unset;
}

.soldouttext {
    position        : unset;
    padding         : unset;
    border          : unset;
    border-radius   : unset;
    background-color: unset;
    color           : unset;
    display         : unset;
    width           : unset;
    margin-left     : unset;
    font-size       : unset;
    text-align      : unset;
    right           : 0;
    top             : -65px;
}

@import './cat1';
@import './cat2.module.scss';
@import '../../../../components/ProductRecommendation/ProductRecommendationGlobal.scss';
@import '../../../../components/ProductRecommendation/Desktop/ProductRecommendationDesktop.module.scss';