@import 'src/static/css/global/global.scss';
.product-recommendation-desktop{
    .hk-variants-box-body{
        display: flex;
        flex-direction: column!important;
        padding: 0!important;
        .hk-variants-box-body--left{
            width: 100% !important;
            .variant-img-wrap{
                margin: 10px 0 14px;
                .variant-img{
                    height: 144px !important;
                    text-align: center;
                    img{
                        width: auto;
                        max-height: 100%;
                        max-width: 100%;
                        margin: 0 auto;
                    }
                    .offerCircle{
                        display: none;
                    }
                }
            }
        }
        .hk-variants-box-body--right{
            width: 100% !important;
            .variant-name {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                font-size: 16px;
                line-height: 22px;
                font-weight: $medium;
                color: #2C2C2C !important;
            }
            .variant-desc{
                margin: 2px 0 6px !important;
                display: flex;
                align-items: center;
                .vegnonveg--icon{
                    margin-right: 7px;
                    width: 10px;
                }
                span{
                    font-size: 13px;
                    line-height: 22px;
                    color: #2C2C2C;
                }
            }
            .prod-price{
                display: flex;
                align-items: center;
                margin-bottom: 14px;
                .hk-variants-price{
                    margin: 0;
                    >div{
                        display: flex;
                        align-items: center;
                    }
                    .flex{
                        display: none;
                    }
                    .variant-price{
                        margin-right: 18px !important;
                        font-size: 20px !important;
                        line-height: 22px !important;
                        font-weight: 700 !important;
                        color: #2C2C2C !important;
                    }
                    .variant-old-price{
                        font-size: 16px;
                        line-height: 22px;
                        font-weight: 400;
                        color: #898989 !important;
                        text-decoration: line-through;
                        opacity: 1 !important;
                        margin: 0;
                    }
                }
                .prod-discount{
                    margin-left: 11px !important;
                    color: #22B673 !important;
                    font-size: 10px !important;
                    line-height: 16px !important;
                }
            }
        }
    }
    .hk-variants-box-footer{
        position: unset;
        .hk-variants-offer-ribbon{
            display: none;
        }
        .hk-variants-cta{
            display: flex;
            align-items: center;;
            margin-bottom: 20px;
            width: 100%;
            .hk-elements--addToCart{
                width: 49%;
                button{
                    background-color: #423838;
                    color: #FFFFFF;
                    font-size: 12px;
                    line-height: 22px;
                    border-radius: 4px;
                    font-weight: 500;
                    border: none;
                    cursor: pointer;
                    width: 100% !important;
                    height: 34px;
                }
            }
            .hk-elements--buyNow{
                width: 49%;
                button{
                    color: #2C2C2C !important;
                    background-color: #FAC833 !important;
                    font-size: 12px !important;
                    line-height: 22px;
                    border-radius: 4px;
                    font-weight: 700 !important;
                    border: none;
                    cursor: pointer;
                    margin-left: 8px !important;
                    width: 100% !important;
                    height: 34px;
                }
            }
        }
        .notifytext {
            color: unset;
            font-size: unset;
            float: unset;
            padding-left: unset;
            line-height: unset;
        }
        .soldouttext {
            position: unset;
            padding: unset;
            border: unset;
            border-radius: unset;
            background-color: unset;
            color: unset;
            display: unset;
            width: unset;
            margin-left: unset;
            font-size: unset;
            text-align: unset;
            right: 0;
            top: -65px;
        }
    }
}
.product-recommendation-mweb {
    .hk-variants-box-body{
        display: flex;
        flex-direction: row;
        padding: 20px 0;
        .hk-variants-box-body--left{
            width: 30%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .variant-img-wrap{
                .variant-img{
                    height: 75px;
                    img{
                        width: auto;
                        max-height: 100%;
                        max-width: 100%;
                    }
                    .offerCircle{
                        display: none;
                    }
                }
            }
            .variant-rating-count{
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10px;
                span{
                    margin-left: 3px;
                    font-size: 13px;
                    font-weight: 500;
                }
            }
        }
        .hk-variants-box-body--right{
            width: 70%;
            .variant-name {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                font-size: 16px;
                line-height: 22px;
                font-weight: $medium;
                color: #2C2C2C;
            }
            .variant-desc{
                margin: 5px 0 5px;
                display: flex;
                align-items: center;
                .vegnonveg--icon{
                    margin-right: 7px;
                    width: 10px;
                }
                span{
                    font-size: 13px;
                    line-height: 16px;
                    color: #2C2C2C;
                }
            }
        }
    }
    .varient-footer-mob{
        position: unset;
        .img-info-wrap{
            display: none;
        }
        .hk-variants-box-footer{
            .hk-variants-offer-ribbon{
                display: none;
            }
            .hk-variants-cta{
                display: flex;
                align-items: center;;
                margin-bottom: 20px;
                .hk-elements--addToCart{
                    button{
                        background-color: #423838;
                        color: #FFFFFF;
                        font-size: 12px;
                        line-height: 22px;
                        border-radius: 4px;
                        font-weight: 500;
                        border: none;
                        cursor: pointer;
                        width: 100px;
                        height: 34px;
                        margin-right: 0;
                    }
                }
                .hk-elements--buyNow{
                    button{
                        color: #2C2C2C;
                        background-color: #FAC833;
                        font-size: 12px;
                        line-height: 22px;
                        border-radius: 4px;
                        font-weight: 700;
                        border: none;
                        cursor: pointer;
                        margin-left: 8px;
                        width: 100px;
                        height: 34px;
                    }
                }
            }
            .notifytext {
                color: unset;
                font-size: unset;
                float: unset;
                padding-left: unset;
                line-height: unset;
            }
            .soldouttext {
                position: unset;
                padding: unset;
                border: unset;
                border-radius: unset;
                background-color: unset;
                color: unset;
                display: unset;
                width: unset;
                margin-left: unset;
                font-size: unset;
                text-align: unset;
                right: 0;
                top: -65px;
            }
        }
    }
}

//
.lwl-banner-owl {
    .swiper-button-prev, .swiper-button-next {
        width: 48px;
        height: 48px;
        &::after {
            content: ''!important;
        }
    }
    .swiper-button-prev {
        background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/Alp/prevSliderBtnSvg.svg)!important;
        left: 60px!important;
    }
    .swiper-button-next {
        background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/ALP/nextSliderBtnSvg.svg)!important;
        right: 60px!important;
    }
}
.lwl-inf-owl {
    .swiper-pagination-bullets {
        bottom: 5px!important;
        .swiper-pagination-bullet {
            width: 7px;
            height: 7px;
            background: #c3c3c3;
            opacity: 1;
            margin: 0 3.5px!important;
            &.swiper-pagination-bullet-active {
                background: #070907;
            }
        }
    }
    .swiper-button-prev, .swiper-button-next {
        width: 48px;
        height: 48px;
        &::after {
            content: ''!important;
        }
        top: 88%!important;
    }
    .swiper-button-prev {
        background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/ALP/prevSliderBtnSvg.svg)!important;
        left: 85%!important;
    }
    .swiper-button-next {
        background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/ALP/nextSliderBtnSvg.svg)!important;
        right: 60px!important;
        // top: 80%!important;
    }
}
.iron-owl {
    &.mobile {
        .swiper-pagination-bullets {
            bottom: 5px!important;
            left: 0!important;
        }
    }
    .swiper-pagination-bullets {
        bottom: 37px!important;
        left: -5.5%!important;
        .swiper-pagination-bullet {
            width: 7px;
            height: 7px;
            background: #c3c3c3;
            opacity: 1;
            margin: 0 3.5px!important;
            &.swiper-pagination-bullet-active {
                background: #070907;
            }
        }
    }
    .swiper-button-prev, .swiper-button-next {
        width: 48px;
        height: 48px;
        background-position: center;
        background-size: 48px 48px;
        z-index: 999999;
        &::after {
            content: ''!important;
        }
        top: 87.5%!important;
    }
    .swiper-button-prev {
        background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/IronTherapy/prevSliderBtnSvg.svg)!important;
        left: 85%!important;
    }
    .swiper-button-next {
        background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/IronTherapy/nextSliderBtnSvg.svg)!important;
        right: 60px!important;
        // top: 80%!important;
    }
}