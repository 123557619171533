@import 'src/static/css/global/global.scss';
@import 'src/static/css/global/home_global.scss';

.fnt-18 {
    @include fontStyle($fontFamily-oswald, 16px, 500, 20px, 0, $color-primary);

    &.flavour {
        position: relative;
        color: #0085cb;
        text-transform: uppercase;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
    }
}

.strikethrough {
    text-decoration: line-through;
}

.category-main-container {
    .breadcrumb-category {
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(#1B285C, 0.2);

        span {
            @include fontStyle($fontFamily-default, 16px, 500, 26px, 0, rgba($color-primary, 0.5));
            color: rgba($color-primary, 0.5) !important;

            &.home {
                color: rgba($color-primary, 1) !important;
            }
        }
    }

    .search-v4-grouping-data {
        // height: 200px;
        width: 100%;
        // background-color: red;

        margin-bottom: 20px;

        .search-v4-grouping-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;
            padding-left: 10px;
            padding-right: 25px;

            @media screen and (min-width: 1024px) {
                padding-top: 40px;
            }

            // &:last-child{
            // margin-top: 20px;
            // }

            .search-v4-grouping-title {
                @include fontStyle($font-family-barlow, 20px, 500, 18px, 0px, $color-primary);
            }

            .search-v4-grouping-viewAll {
                @include fontStyle($font-family-barlow, 16px, 400, 24px, 0px, $color-primary);
                text-decoration: underline
            }
        }
    }

    .total-products-search {
        display: flex;
        align-items: center;
        padding-left: 10px;
        @include fontStyle($font-family-barlow, 20px, 500, 18px, 0, $color-primary);
        margin-top: 30px;
        margin-bottom: 16px;

        @media screen and (min-width: 1024px) {
            padding-top: 10px;
        }

        .total-products-count {
            font-size: 14px;
            font-weight: 500;
            color: #717261;
            margin-left: 8px;
            line-height: 20px;
        }

    }

    .total-products {
        @include fontStyle($fontFamily-oswald, 16px, 500, 22px, 0, $color-primary);
        // text-transform: uppercase;
        padding: 13px 0 12px;
        border-top: 1px solid rgba($color-primary, 0.2);

        @media screen and (min-width: 1024px) {
            padding: 13px 10px 2px;

        }

        span {
            margin-left: 5px;
        }

        .no-border {
            border: 0;
        }

        span {
            @include fontStyle($fontFamily-oswald, 16px, 500, 22px, 0, $color-primary);
        }
    }

    .not-search {
        @include fontStyle($fontFamily-oswald, 20px, 500, 42px, 0, $color-primary);
        text-transform: uppercase;
        padding: 13px 0 20px;
    }

    .search-instead {
        font-size: 16px;
        line-height: 19px;
        color: #707070;
        font-weight: 400;
        margin: 0 0 20px 0;

        @media screen and (min-width: 1024px) {
            padding-left: 10px;
            margin: 0 0 0px 0;
            font-family: "Roboto";
            font-size: 14px;
            font-weight: 500;
            color: #080A08;

        }

        span {
            color: #000000;
            font-weight: 600;
            text-decoration: underline;
            text-underline-offset: 1px;
            text-decoration-color: #707070;

            @media screen and (min-width: 1024px) {
                // padding-left: 10px;
                margin: 0 0 0px 0;
                font-family: "Roboto";
                font-size: 14px;
                font-weight: 500;
                color: #080A08;

            }

            cursor: pointer;
        }
    }

    .cat__main-container {
        display: flex;
        width: 100%;
        flex-flow: column;

        .sticky--filter {
            display: block;
            margin-bottom: 20px;

            @media screen and (max-width:1023px) {
                position: sticky;
                top: 60px;
                z-index: 1;
                min-height: 30px;
                background-color: #FFF;
                width: calc(100% + 26px);
                padding: 4px 10px 0 10px;
                margin-left: -13px;
            }

            .filter-container {
                display: flex;
                justify-content: space-between;
                flex-wrap: nowrap;
                width: 100%;

                //overflow-x: hidden;
                .filter-left-wrap {
                    width: calc(100% - 40px);
                    //overflow-x: hidden;
                    padding-bottom: 4px;
                    position: relative;
                }

                .filter-left {
                    display: flex;
                    width: 100%;
                    padding-right: 14px;

                    // overflow: auto;
                    @media screen and (min-width:992px) {
                        gap: 20px;
                    }

                    @media screen and (max-width:991px) {
                        width: 100%;
                        gap: 14px;
                    }

                    .filter-item {
                        cursor: pointer;
                        @include fontStyle($fontFamily-default, 14px, 600, normal, 0px, #2a2c65);
                        border: 2px solid #71717A;
                        border-radius: 8px;
                        padding: 3px 4px 0px 4px;
                        display: flex;
                        flex-direction: column;

                        .filter-nm {
                            @include fontStyle($fontFamily-default, 14px, 600, normal, 0px, #2a2c65);
                            display: flex;
                            justify-content: space-between;
                        }
                    }

                    .selected {
                        background-color: #f3f3ff;
                        border: 2px solid #2a2c6599;

                        .filter-nm {
                            font-weight: 600;
                        }

                        .selected_filter {
                            font-weight: 400;
                        }
                    }

                    .filter-main-container {
                        position: relative;

                        @media screen and (max-width:992px) {
                            width: 33%;
                        }

                        @media screen and (min-width:1024px) {
                            width: 33%;
                            max-width: 250px;
                        }

                        .filter-drop-down-wrap {
                            position: absolute;
                            top: 100%;
                            left: 0px;
                            z-index: 9;
                            background: transparent;
                            min-width: 120px;
                            padding-top: 5px;
                            width: 100%;

                            @media screen and (min-width:360px) {
                                min-width: 140px;
                            }

                            @media screen and (min-width:420px) {
                                min-width: 160px;
                            }

                            // &.right_start {
                            //     left: auto;
                            //     right: 0px;
                            //     min-width: 180px;
                            // }
                        }

                        .filter-drop-down {
                            background: #F3F3FF;
                            border-radius: 5px;
                            padding: 10px;
                            border-radius: 10px;
                            box-shadow: 0 14px 7px 0 rgba(0, 0, 0, 0.04);
                            background-color: #f3f3ff;

                            .item {
                                @include fontStyle($fontFamily-default, 14px, 400, normal, 0px, #2a2c65);
                                position: relative;
                                cursor: pointer;
                                padding-left: 18px;
                                margin-bottom: 8px;
                                width: 100%;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;

                                .customRadio {
                                    position: absolute;
                                    left: 0;
                                    top: 2px;
                                }

                                img {
                                    width: 14px;
                                    position: absolute;
                                    left: 0;
                                    top: 4px;
                                }
                            }

                            .selected-item {
                                font-weight: 600;
                            }
                        }
                    }
                    .filter-disabled{
                        pointer-events: none;
                        opacity: .7;
                    }
                }

                .filter-right {
                    border-radius: 8px;
                    // padding      : 7px 9px;
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    cursor: pointer;
                    position: relative;

                    .sorting-items {
                        position: absolute;
                    }

                    .filter-top {
                        display: flex;
                        align-items: center;
                        border: 2px solid #71717A;
                        border-radius: 5px;
                        padding: 12px 8px;

                        @media screen and (max-width:1023px) {
                            padding: 11px 8px;
                        }

                        .text {
                            @include fontStyle($fontFamily-default, 14px, 500, normal, 0px, #2a2c65);
                            padding: 0px 3px;
                        }
                    }

                    .drop-down-wrap {
                        position: absolute;
                        top: 100%;
                        z-index: 9;
                        min-width: 180px;
                        right: 0px;
                        padding-top: 5px;
                    }

                    .drop-down {
                        border: 1px solid #71717A;
                        width: 100%;
                        min-width: 180px;
                        border-radius: 5px;
                        padding: 10px;
                        background-color: #f3f3ff;

                        @media screen and (max-width:1023px) {
                            right: 10%;
                            left: unset;
                        }

                        ul {
                            li {
                                position: relative;
                                padding-left: 18px;
                                line-height: normal;
                                margin-bottom: 8px;

                                .customRadio {
                                    position: absolute;
                                    left: 0;
                                    top: 2px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .span12 {
            width: 100%;

            h2 {
                display: flex;
                justify-content: center;
            }
        }
    }

    .cat-page-header {
        h1 {
            margin: 0;
            margin-bottom: 11px;
            @include fontStyle($font-family-barlow, 28px, 600, normal, 0px, $color-primary);
        }

        .header {
            display: flex;
            margin-top: 8px;

            @media screen and (min-width:1023px) {
                margin: 15px 0px 21px 0px;
            }
        }
    }

    .span12 {
        &.prdct-dtls-blck {
            .sales-banner-outer {
                display: block;
                width: 100%;
                min-height: 225px;
                margin-bottom: 30px;
            }

            .search-instead-div {
                margin: 10px 0px 40px 0px;
                padding-left: 10px;

                .search-instead-p {
                    font-family: "Roboto";
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 20px;
                    color: #080A08;

                    span {
                        font-family: "Roboto";
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 20px;
                        color: #080A08;
                    }
                }

                .search-instead-p-another {
                    .span {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                        color: #080A08;
                        text-decoration: underline;
                    }
                }
            }

            .total-products {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                color: #080A08;
                margin: 10px 0px;

                @media screen and (min-width: 1024px) {
                    font-weight: 500;
                    font-family: 'Roboto';
                    font-size: 16px;

                }

                .total-products-count {
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    color: #080A08;
                    margin-left: 5px
                }
            }

            img.sales-banner {
                display: flex;
                width: 100%;
                // margin-bottom: 30px;
                border-radius: $border-radius-2;
                min-height: 225px;

                &.no-margin {
                    margin: 0;
                }
            }

            #variantResultView1,
            #variantResultView,
            .variantResultview1 {
                .cat-l2 {
                    width:100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin-bottom: 30px;

                    @media screen and (min-width:1023px) {
                        display: inline-flex;
                        -webkit-flex-wrap: wrap;
                        flex-wrap: wrap;
                        -webkit-justify-content: space-between;
                        -moz-box-pack: justify;
                        justify-content: start;
                        margin-bottom: 30px;
                        gap: 10px;

                    }

                    .variant-container {
                        width: calc(100%/2 - 10px);
                        padding: 8px 11px 8px;
                        border-radius: 4px;
                        box-shadow: 0px 3px 20px #3941491A;
                        border: 1.8px solid #D1D4DE;

                        .hk-variants-cta {
                            padding: 9px 9px 0px 9px;
                            border-top: 1px solid #ebecf1;
                            display: flex;

                            .hk-elements--buyNow {
                                width: 50%;
                                margin: 0;
                                text-align: right;
                                padding-right: 5px;

                                button {
                                    width: 100%;
                                    border: 1px;
                                    outline: none;
                                    background: transparent;
                                    border-radius: 2px;
                                    height: 40px;
                                    font-family: "Roboto";
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 17px;
                                    letter-spacing: 0px;
                                    color: #080A08;
                                }
                            }

                            .hk-elements--addToCart {
                                button {
                                    width: 100%;
                                    border: 1px solid #161616;
                                    outline: none;
                                    background: #080a08;
                                    border-radius: 2px;
                                    height: 40px;
                                    font-family: "Roboto";
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 17px;
                                    letter-spacing: 0px;
                                    color: #FFFFFF;
                                    min-width: 200px;
                                    border-radius: 2px;
                                }
                            }
                        }

                        .float-wrapper {
                            display: flex;
                            margin-bottom: 10px;
                        }

                        .cat-item-oos {
                            text-align: center;
                            align-items: center;
                            display: flex;
                            justify-content: center;
                            display: table;
                            margin: 7px auto;
                            width: 100%;
                            padding: 10px 0px;
                            margin-top: 17px;
                            font-size: 15px;
                            cursor: not-allowed;
                            font-weight: 600;
                            color: #aeb1bc;
                            width: 90%;
                            padding: 12px 0;
                            border: 1px solid #aeb1bc;
                            border-radius: 2px;
                        }

                        .sale {
                            display: flex;
                            margin-bottom: 10px;
                        }

                        .image-container {
                            position: relative;
                            text-align: center;
                            min-width: 140px;
                            max-width: auto;
                            min-height: 100px;
                            max-height: 180px;
                            border-radius: 6px;
                            // background-color: #FFFFFF;
                            background-color: #F2F3F5;
                            margin-right: 10px;

                            .discount-content {
                                position: absolute;
                                top: 0px;
                                right: 0px;
                                width: 40px;
                                height: 40px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }

                                .discount {
                                    position: absolute;
                                    top: 0px;
                                    left: 8px;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;

                                    .text {
                                        font-size: 14px;
                                        font-weight: bold;
                                    }

                                    .sale {
                                        font-size: 11px;
                                        margin-top: -12px;
                                    }
                                }
                            }

                            img.main {
                                // height: 100%;
                                // position: absolute;
                                // width: 100%;
                                // height: 100%;
                                top: 50%;
                                left: 50%;
                                // transform: translate(-50%, -50%);
                                border-radius: 10px;
                                width: 120px;
                                height: 120px;
                                background-color: inherit;
                                mix-blend-mode: multiply;
                            }

                            .veg-nonveg-icon {
                                height: 15px;
                                width: 15px;
                                position: absolute;
                                bottom: 5px;
                                left: 6px;
                            }
                        }

                        .out-of-stock {
                            @include fontStyle($fontFamily-default, 16px, 600, 20px, 0px, $color-primary);
                            margin-top: auto;
                            opacity: 0.3;
                        }

                        .details {
                            margin-top: 10px;
                            display: flex;
                            flex-direction: column;

                            // justify-content: space-between;
                            .info {
                                @include fontStyle($fontFamily-default, 14px, 500, 20px, 0px, #20262E);
                                text-overflow: ellipsis;
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                line-height: 20px;
                                opacity: 0.9;

                                .name {
                                    text-transform: uppercase;
                                }

                                .flavor-wt {
                                    color: $color-primary;
                                    opacity: 0.9;
                                }
                            }

                            .flavor-wt {
                                // color: $grey-clor;
                                text-transform: none;
                                @include fontStyle($fontFamily-bold, 14px, 400, 20px, 0px, $color-primary);
                                text-overflow: ellipsis;
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                                line-height: 24px;
                                opacity: 0.9;
                            }

                            .price {
                                align-items: center;
                                position: relative;
                                display: flex;
                                gap: 3px;

                                .actual-price {
                                    @include fontStyle($fontFamily-default, 14px, 400, 12px, 0, $color-primary);
                                    opacity: 0.7;
                                    padding-top: 3px
                                }

                                .upto-price {
                                    @include fontStyle($fontFamily-bold, 14px, 400, 22px, 0, #51B279);
                                    padding-left: 5px;
                                    padding-top: 3px;
                                }

                                .offer-price {
                                    @include fontStyle($fontFamily-bold, 16px, 600, 12px, 0px, $color-primary);
                                    // margin-left: auto;
                                }
                            }

                            .rating-container {
                                display: flex;
                                gap: 10px;
                                align-items: center;
                                margin-top: 4px;

                                .variant-rating {
                                    width: 44px;
                                    height: 20px;
                                    background-color: #23A247;
                                    border-radius: 2px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    gap: 5px;

                                    p {
                                        @include fontStyle($fontFamily-bold, 14px, 500, 21px, 0px, #fff);
                                    }
                                }
                            }

                        }

                        .wishlist {
                            align-self: flex-start;
                            margin-left: auto;
                        }
                    }
                }

                .load-more {
                    @include viewAll;
                }
            }
        }
    }

}

.search-instead-p-another {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #080A08;
    margin-top: 12px;

    @media screen and (max-width: 1024px) {
        font-size: 16px;
        font-family: "Roboto";
        color: #707070;

    }

    // text-decoration: underline;
}

.search-instead-text-desk {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #080A08;
    // opacity: 0.6;
    text-decoration: underline;
    cursor: pointer;
    // opacity: 0.6;
}

.hk-swiper {
    position: relative;

    // padding-left: 10px;
    .swiper-button-prev,
    .swiper-button-next {
        background-repeat: no-repeat !important;
        max-width: 100%;
        max-height: 100%;
        border: none;
        box-shadow: none;
        // background-position: 100%!important;
        background-size: contain;
        transform: none;
        border-radius: 0;
        top: 53%;
        z-index: 2;

        &::after {
            display: none;
        }

        &.swiper-button-disabled {
            opacity: 0.1;
        }
    }

    .swiper-button-disabled {
        pointer-events: auto !important;

        &:hover {
            cursor: not-allowed !important;
        }
    }

    .swiper-button-prev {
        background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/newGritzo/grey_arrow_left.svg) !important;
        left: -13px !important;

        @media screen and (min-width:1240px) {
            left: -25px !important;
        }
    }

    .swiper-button-next {
        background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/newGritzo/grey_arrow_right.svg) !important;
        right: -15px !important;
    }

    .BS-Desk {
        margin: 0 25px 0 10px;
        position: unset;

        .swiper-wrapper {
            // padding: 0 10px;
            margin: 0 -10px;

            .swiper-slide {
                padding: 0 0px 0px 10px;
                margin-right: 6px !important;

                .popular-product {
                    .hk-elements--addToCart {
                        padding-top: 10px;

                        button {
                            border: none;
                            outline: none;
                            background: #080A08;
                            border-radius: 2px;
                            height: 31px;
                            font-family: "Roboto";
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 17px;
                            letter-spacing: 0px;
                            color: #FFFFFF;
                            min-width: 100px;
                            border-radius: 2px;
                        }
                    }
                }
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            width: 24px;
            height: 24px;
        }
    }

    .Pop-Cat {
        margin: 0 15px;
        position: unset;

        .swiper-button-prev,
        .swiper-button-next {
            width: 20px;
            height: 20px;
            top: 58%;
        }

        .swiper-button-prev {
            left: -10px !important;

        }

        .swiper-button-next {
            right: -10px !important;
        }

        @media screen and (min-width:768px) {
            .swiper-button-disabled {
                display: none;
            }
        }
    }

    .Shop-Flav-D {
        padding: 0 25px;
        max-width: 1024px;
        margin: 0 auto;

        @media screen and (min-width:992px) {
            .swiper-button-disabled {
                display: none;
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            width: 23.5px;
            height: 23.5px;
        }

        .swiper-button-prev {
            left: 2px !important;

        }

        .swiper-button-next {
            right: 2px !important;
        }
    }

    .home-slider-M {
        display: flex;
        flex-direction: column;

        .swiper-wrapper {
            order: 1;
        }

        .swiper-pagination-bullets {
            order: 2;
            bottom: 12px;

            .swiper-pagination-bullet {
                zoom: 1;
                background: #ffffff;
                opacity: 0.2;
                padding: 0 !important;
                width: 6px;
                height: 6px;
                margin: 0 3px;

                &.swiper-pagination-bullet-active {
                    opacity: 1;
                    width: 7px;
                    height: 7px;
                }
            }
        }
    }

    .home-slider-D {

        .swiper-button-prev,
        .swiper-button-next {
            width: 30px;
            height: 30px;
        }

        .swiper-button-prev {
            background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/slick-slider/slider1/left.png) !important;
            left: 30px !important;
        }

        .swiper-button-next {
            background-image: url(https://static1.hkrtcdn.com/gritzo/static/media/slick-slider/slider1/right.png) !important;
            right: 30px !important;
        }

        &::after {
            content: '';
            background-color: $yellow_color;
            width: 100%;
            height: 10px;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 9;
        }
    }

    .home-slider-D2 {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 55%;
        margin-bottom: -3%;
        border-bottom: 4px solid $yellow_color;

        .swiper-slide {
            img {
                cursor: pointer;
            }
        }
    }
}

.trending__container {
    .BS__container {
        padding: 0 0 34px;

        .title-wrap {
            display: none;
        }
    }
}



.BS-Desk {
    .swiper-button-next {
        width: 26px;
        height: 26px;
        background-image: url('/static/media/newGritzo/grey_arrow_right.svg');

        &::after {
            content: '';
        }

    }

    .swiper-button-prev {
        width: 26px;
        height: 26px;
        background-image: url('/static/media/newGritzo/grey_arrow_left.svg');

        &::after {
            content: '';
        }

        .swiper-button-disabled {
            opacity: 0.1;
        }
    }
}

.load-more-topcatpage {
    @include viewAll;
}

//new css
.filter-1 {
    background: #cadfed;
    border-radius: 50px;
    height: auto;
    padding: 41px 63px 60px;
    position: relative;

    @media screen and (max-width: 1023px) {
        border-radius: 0 0 50px 50px;
        padding: 41px 3px 60px;

    }

    .f1-inner-cont {
        .f1-inner-cont-label {
            color: #002140;
            font-family: $fontFamily-default;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: .36px;
            display: block;
            margin: 0 0 15px 0;

            @media screen and (max-width: 1023px) {
                display: flex;
                justify-content: center;
            }

        }

        .f1-inner-tabs {
            display: flex;
            gap: 15px;

            @media screen and (max-width: 1023px) {
                justify-content: center;
                gap: 1px;
            }

            .f1-inner-tab {
                width: 270px;
                height: 68px;
                padding: 20px 26.667px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: #ebebeb;
                border-radius: 12px;
                box-shadow: 0 6.666666030883789px 19.999998092651367px #00000052;
                cursor: pointer;

                @media screen and (max-width:1023px) {
                    width: 129px !important;
                    margin: 0 10px;
                    height: 41px;
                    position: relative;
                    display: flex;
                    padding: 20px 26.667px;
                    align-items: center;
                    gap: 16.667px;
                    flex-shrink: 0;
                    border-radius: 12px;
                    background: #ebebeb;
                    box-shadow: 0 6.666666030883789px 19.999998092651367px
                }

                span {
                    font-size: 24px;

                    @media screen and (max-width:1024px) {
                        color: #575b65;
                        // font-size: 14px;
                    }
                }

                img {
                    position: relative;
                    bottom: 4px;

                    // overflow: auto;
                    @media screen and (max-width:1024px) {
                        position: absolute;
                        right: 5px;
                        bottom: 0;
                        width: 40px;
                    }

                }

                &.selected {
                    background: #2420ED;
                    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.32);
                    transition: all .25s linear;

                    span {
                        font-weight: 500;
                        color: #fff;
                        transition: all .25s linear;
                    }
                }
            }
        }
    }
}

.filter-2 {
    margin: 40px 0 0 0;
    border-bottom: 2px solid #e1e1e1;

    .f2-inner-cont {
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;

        @media screen and (min-width:1024px) {
            display: inline-flex;
            gap: 31px;
            justify-content: start;
        }

        .f2-inner-tab {
            // width: 195px;
            height: 77px;
            display: flex;
            align-items: center;
            padding: 10px 30px;
            justify-content: space-between;
            position: relative;
            cursor: pointer;
            flex-direction: row;
            gap: 9px;
            margin: auto;

            @media screen and (max-width:1023px) {
                padding: 10px !important;
                min-width: 70px;
            }

            &.selected {
                span {
                    font-weight: 600;
                }

                // border-bottom: 2px solid #2420ed;
                &::before {
                    content: "";
                    position: absolute;
                    bottom: 0px;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #2420ed;

                }
            }

            span {
                color: #2420ed;
                font-family: $fontFamily-default;
                font-size: 20px;

                @media screen and (max-width:1024px) {
                    font-size: 14px !important;
                    width: auto;
                    line-height: 14px;
                }
            }
        }
    }
}

.customRadio {
    border: 1px #2A2C65 solid;
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 100%;
    position: relative;
    cursor: pointer;

    &.rselected {
        &::after {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 8px;
            height: 8px;
            content: '';
            background: #2A2C65;
            border-radius: 100%;
            transform: translate(-50%, -50%);
        }

    }
}

.singleLineEllipse {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}